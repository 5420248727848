import Layout from '@/layout'
import { p__route, p__group_route } from '@/plugins/prototypes'
import i18n from '@/plugins/language'

const hidden_list = [
    {
        path: `${p__route.campaign}/create`,
        component: () => import('@/views/create-campaign'),
        meta: {
            title: i18n.t('common.campaigns'),
            icon: 'el-icon-news'
        },
        hidden: true
    },
    {
        path: `${p__route.tiktok_business}/connect`,
        component: () => import('@/views/connect-tiktok-business'),
        meta: {
            title: i18n.t('common.connect_tiktok_account'),
            icon: 'el-icon-user'
        },
        hidden: true
    }
    // {
    //     path: `${p__route.bc}/:id`,
    //     component: () => import('@/views/detail-business-center'),
    //     meta: {
    //         title: i18n.t('common.business_centers'),
    //         icon: 'el-icon-monitor'
    //     },
    //     hidden: true
    // }
]

export const adsManagerRoutes = {
    path: p__group_route.ads_manager,
    component: Layout,
    name: i18n.t('common.ads_manager'),
    isGroup: true,
    children: [
        {
            path: p__route.campaign,
            component: () => import('@/views/campaign'),
            meta: {
                title: i18n.t('common.campaigns'),
                icon: 'el-icon-news'
            }
        },
        {
            path: p__route.bc,
            component: () => import('@/views/business-center'),
            meta: {
                title: i18n.t('common.business_centers'),
                icon: 'el-icon-monitor'
            }
        },
        // {
        //     path: p__route.ads_account,
        //     component: () => import('@/views/advertiser-account'),
        //     meta: {
        //         title: i18n.t('common.advertiser_accounts'),
        //         icon: 'el-icon-star-off'
        //     }
        // },
        {
            path: p__route.tiktok_shop,
            component: () => import('@/views/tiktok-shop'),
            meta: {
                title: i18n.t('common.tiktok_shops'),
                icon: 'el-icon-sell'
            }
        },
        {
            path: p__route.tiktok_identity,
            component: () => import('@/views/tiktok-identity'),
            meta: {
                title: i18n.t('common.tiktok_identity'),
                icon: 'el-icon-user'
            }
        },
        {
            path: p__route.autobot,
            component: () => import('@/views/autobot'),
            meta: {
                title: i18n.t('common.autobot'),
                icon: 'el-icon-refresh'
            }
        },
        // {
        //     path: p__route.pixel,
        //     component: () => import('@/views/pixel'),
        //     meta: {
        //         title: 'Pixel',
        //         icon: 'el-icon-s-grid'
        //     }
        // },
        {
            path: p__route.audience,
            component: () => import('@/views/audience'),
            meta: {
                title: i18n.t('common.audience'),
                icon: 'el-icon-news'
            }
        },
        {
            path: p__route.tiktok_business,
            component: () => import('@/views/tiktok-business'),
            meta: {
                title: i18n.t('common.tiktok_business'),
                icon: 'el-icon-connection'
            }
        },
        {
            path: p__route.payment,
            component: () => import('@/views/payment'),
            meta: {
                title: `${i18n.t('common.recharge')}`,
                icon: 'el-icon-wallet'
            }
        },
        ...hidden_list
    ]
}
