import request from './_config'

const path = `audience`

export function getAllAudiences(tiktok_account_id, params) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function createAudienceByRule(tiktok_account_id, data) {
    return request({
        url: `${path}/create_by_rule/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function createLookalikeAudience(tiktok_account_id, data) {
    return request({
        url: `${path}/lookalike/create/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function uploadAudienceFile(tiktok_account_id, file) {
    const formData = new FormData()
    formData.append('file', file)
    return request({
        url: `${path}/upload_file/${tiktok_account_id}`,
        // url: `http://localhost:8080/api/v1/audience/upload_file/${tiktok_account_id}`,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function getAvailableStore(tiktok_account_id, params) {
    return request({
        url: `store/get_available_store/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function shareAudiences(tiktok_account_id, data) {
    return request({
        url: `${path}/share/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function deleteAudiences(tiktok_account_id, data) {
    return request({
        url: `${path}/delete/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function updateAudience(tiktok_account_id, data) {
    return request({
        url: `${path}/update/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function applyToAdGroups(tiktok_account_id, data) {
    return request({
        url: `${path}/apply/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function getAudienceDetails(tiktok_account_id, params) {
    return request({
        url: `${path}/details/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function getApplicationLogAudience(tiktok_account_id, params) {
    return request({
        url: `${path}/apply/log/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function getSharingLogAudience(tiktok_account_id, params) {
    return request({
        url: `${path}/share/log/${tiktok_account_id}`,
        method: 'get',
        params
    })
}
