import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import { adsManagerRoutes } from './modules/ads-manager'
import { generalRoutes } from './modules/general'
import { toolRoutes } from './modules/tool'
import { p__route } from '@/plugins/prototypes'
import i18n from '@/plugins/language'
import Layout from '@/layout'

export const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/_system/login'),
        hidden: true
    },
    {
        path: '/verify-tiktok-account',
        component: () => import('@/views/_system/verify-tiktok-business'),
        hidden: true
    },
    {
        path: '/verify-tiktok-shop',
        component: () => import('@/views/_system/verify-tiktok-shop'),
        hidden: true
    },
    {
        path: '/register',
        component: () => import('@/views/_system/register'),
        hidden: true
    },
    {
        path: '/forget-password',
        component: () => import('@/views/_system/forget-password'),
        hidden: true
    },
    {
        path: '/reset-password',
        component: () => import('@/views/_system/reset-password'),
        hidden: true
    },
    {
        path: p__route.payment_webview,
        component: () => import('@/views/payment-webview'),
        meta: {
            title: i18n.t('common.payment'),
            icon: 'el-icon-bank-card'
        },
        hidden: true
    },
    {
        path: '*',
        component: () => import('@/views/_system/not-found'),
        hidden: true
    }
]

const dashboardRouter = {
    path: '/',
    component: Layout,
    isGroup: true,
    name: i18n.t('common.overview'),
    children: [
        {
            path: '/',
            component: () => import('@/views/dashboard'),
            meta: {
                title: `${i18n.t('common.dashboard')}`,
                icon: 'el-icon-data-line'
            }
        },
        {
            path: '/report-day',
            component: () => import('@/views/report-day'),
            meta: {
                title: `${i18n.t('common.report_date')}`,
                icon: 'el-icon-data-line'
            }
        },
        // {
        //     path: '/report-video',
        //     component: () => import('@/views/report-video'),
        //     meta: {
        //         title: `${i18n.t('common.report_video')}`,
        //         icon: 'el-icon-data-line'
        //     }
        // }
        {
            path: '/report-gmv-max',
            component: () => import('@/views/report-gmv'),
            meta: {
                title: `${i18n.t('common.report_gmv')}`,
                icon: 'el-icon-data-line'
            }
        }
    ]
}

export const asyncRoutes = [
    dashboardRouter,
    adsManagerRoutes,
    // toolRoutes,
    generalRoutes
]

const createRouter = () =>
    new Router({
        mode: 'history', // require service support
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRoutes
    })

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
