<template>
    <div
        class="w-full h-[64px] px-5 bg-white flex items-center border-border border-[1px] shadow-sm overflow-hidden"
    >
        <router-link to="/">
            <div class="flex items-center space-x-2 w-[200px]">
                <img
                    class="w-11 h-11"
                    :src="require('@/assets/images/logo_brand.png')"
                />
                <div>
                    <div class="flex items-end space-x-1">
                        <p
                            class="text-brand-atosa text-2xl font-semibold uppercase"
                        >
                            ATAME
                        </p>
                    </div>
                    <p class="font-semibold text-xxs text-[#125570]">
                        av.3.0.5
                    </p>
                </div>
            </div>
        </router-link>

        <label-outside-input-select
            id="tg_3"
            :label="$t('common.tiktok_business')"
        >
            <el-select
                :value="m__selected_tiktok_business"
                value-key="core_user_id"
                size="small"
                filterable
                :placeholder="$t('input.placeholder.select_tiktok_account')"
                class="tablet:w-[240px] desktop:w-[280px]"
                @change="handleChangeTiktokBusiness"
            >
                <el-option-group :label="$t('common.tiktok_business')">
                    <el-option
                        v-for="item in tiktok_businesses"
                        :key="item.core_user_id"
                        :label="item.custom_info.display_name || item.user_name"
                        :value="item"
                    >
                        <div class="flex items-center py-1 space-x-2">
                            <el-avatar :size="38">
                                <img
                                    v-if="item.custom_info.avatar_url"
                                    class="object-cover"
                                    :src="`${VUE_APP_ATOSA_TIKTOK_SERVER_STATIC}/${item.custom_info.avatar_url}`"
                                    alt=""
                                />
                                <p v-else>{{ item.user_name }}</p>
                            </el-avatar>
                            <div class="flex flex-col">
                                <span
                                    class="text-xs font-semibold leading-3"
                                >
                                    {{
                                        item.custom_info.display_name ||
                                        item.user_name
                                    }}
                                </span>
                                <span class="leading-5">
                                    {{ item.email }}
                                </span>
                                <span class="component-text-desc leading-3">
                                    #{{ item.core_user_id }}
                                </span>
                            </div>
                        </div>
                    </el-option>
                </el-option-group>
            </el-select>
        </label-outside-input-select>

        <div class="ml-2">
            <label-outside-input-select :label="$t('common.total_balance')">
                <el-input
                    size="small"
                    class="w-[120px]"
                    :value="total_balance"
                ></el-input>
            </label-outside-input-select>
        </div>

        <div class="!ml-auto"></div>

        <div v-if="main_package">
            <div
                v-if="user_info && user_info.user.is_manager"
                id="tg_2"
                class="opacity-80 hover:opacity-90 h-[64px] px-4 mr-6 cursor-pointer hidden screen:block"
                :class="[expiry ? 'bg-brand-atosa--light' : 'bg-red']"
                @click="
                    $router.push({
                        path: `${p__group_route.general}/${p__route.package}`
                    })
                "
            >
                <div class="flex flex-col justify-center h-full text-white">
                    <p class="text-sm font-semibold">
                        <i class="el-icon-postcard text-sm"></i>
                        {{ main_package.package_data.name }}
                    </p>

                    <p class="text-xs">
                        {{
                            (main_package.license_to * 1000)
                                | f__format_moment($t('common.format_fulldate'))
                        }}
                        -
                        {{ expiry ? $t('common.using') : $t('common.expire') }}
                    </p>
                </div>
            </div>
        </div>
        <div
            v-else
            class="h-[64px] px-4 mr-6 cursor-pointer hidden desktop:block bg-yellow"
            @click="
                $router.push({
                    path: `${p__group_route.general}/${p__route.package}`
                })
            "
        >
            <div class="flex flex-col justify-center h-full text-white">
                <p class="text-sm font-semibold">
                    {{ $t('common.havent_package') }}
                </p>

                <p class="text-xs">
                    {{ $t('common.just_register_package') }}
                </p>
            </div>
        </div>

        <div class="relative flex items-center space-x-4">
            <a
                :href="
                    selected_language.code === 'en'
                        ? VUE_APP_ATOSA_FEEDBACK_FORM_EN
                        : VUE_APP_ATOSA_FEEDBACK_FORM
                "
                target="_blank"
            >
                <el-tooltip
                    effect="dark"
                    :content="$t('atosa_core.description_form')"
                    placement="bottom"
                >
                    <el-tag
type="warning"
size="small"
><span class="font-semibold">
                            {{ $t('common.form') }}</span></el-tag>
                </el-tooltip>
            </a>

            <a :href="s__website" target="_blank">
                <el-tooltip
                    effect="dark"
                    :content="$t('atosa_core.find_out_more')"
                    placement="bottom"
                >
                    <el-tag
size="small"
><span class="font-semibold">ATOSA</span></el-tag>
                </el-tooltip>
            </a>

            <el-dropdown class="laptop:block hidden" trigger="click">
                <div>
                    <img
                        class="w-9 h-6 rounded-[3px]"
                        :src="selected_language.image"
                        alt=""
                    />
                </div>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(item, index) in p__list_select_languages"
                        :key="index"
                        :class="{
                            'is-active': item.code === selected_language.code
                        }"
                        @click.native="handleTranslate(item.code)"
                    >
                        <div class="flex items-center space-x-2">
                            <img class="w-5 h-3" :src="item.image" alt="" />

                            <p>{{ item.description }}</p>
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown :hide-on-click="false" trigger="click">
                <div
                    class="flex items-center space-x-1 text-sm font-semibold cursor-pointer"
                >
                    <div class="tablet:max-w-[200px] desktop:w-auto">
                        <p class="text-brand-atosa--light truncate">
                            {{ user_info.username }}
                        </p>
                        <p
                            v-if="main_package"
                            class="laptop:hidden text-xs font-semibold"
                            :class="[
                                expiry ? 'text-brand-atosa--light' : 'text-red'
                            ]"
                        >
                            {{ main_package.package_data.name }}
                        </p>
                    </div>

                    <i class="el-icon-caret-bottom text-brand-atosa--light"></i>
                </div>

                <el-dropdown-menu slot="dropdown">
                    <div class="h-fit flex items-center px-4 py-1 space-x-2">
                        <el-avatar :size="48">
                            {{ display_name }}
                        </el-avatar>

                        <div>
                            <p class="text-xs font-semibold">
                                {{ display_name }}
                            </p>
                            <span class="component-text-desc">{{ email }}</span>
                        </div>
                    </div>
                    <div
                        v-if="
                            main_package &&
                            user_info &&
                            user_info.user.is_manager
                        "
                        class="opacity-80 hover:opacity-90 h-fit desktop:hidden px-4 py-2 my-3 cursor-pointer"
                        :class="[expiry ? 'bg-brand-atosa--light' : 'bg-red']"
                        @click="
                            $router.push({
                                path: `${p__group_route.general}/${p__route.package}`
                            })
                        "
                    >
                        <div
                            class="flex flex-col justify-center h-full text-white"
                        >
                            <p class="text-sm font-semibold">
                                <i class="el-icon-postcard text-sm"></i>
                                {{ main_package.package_data.name }}
                            </p>

                            <p class="text-xs">
                                {{
                                    (main_package.license_to * 1000)
                                        | f__format_moment(
                                            $t('common.format_fulldate')
                                        )
                                }}
                                -
                                {{
                                    expiry
                                        ? $t('common.using')
                                        : $t('common.expire')
                                }}
                            </p>
                        </div>
                    </div>
                    <el-dropdown-item class="laptop:hidden block" divided>
                        <el-dropdown trigger="click">
                            <div class="flex items-center py-1 space-x-2">
                                <img
                                    class="w-9 h-6 rounded-[3px]"
                                    :src="selected_language.image"
                                    alt=""
                                />

                                <p>
                                    {{ selected_language.description }}
                                </p>
                            </div>

                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    v-for="(
                                        item, index
                                    ) in p__list_select_languages"
                                    :key="index"
                                    :class="{
                                        'is-active':
                                            item.code === selected_language.code
                                    }"
                                    @click.native="handleTranslate(item.code)"
                                >
                                    <div class="flex items-center space-x-2">
                                        <img
                                            class="w-5 h-3"
                                            :src="item.image"
                                            alt=""
                                        />

                                        <p>{{ item.description }}</p>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-dropdown-item>
                    <el-dropdown-item
                        divided
                        icon="el-icon-key"
                        @click.native="show_change_password_dialog = true"
                    >
                        {{ $t('common.change_password') }}
                    </el-dropdown-item>
                    <el-dropdown-item
                        divided
                        icon="el-icon-back"
                        @click.native="handleLogOut"
                    >
                        {{ $t('button.log_out') }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <ChangePasswordDialog
            :visible.sync="show_change_password_dialog"
        ></ChangePasswordDialog>
    </div>
</template>

<script>
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import moment from 'moment'
import ChangePasswordDialog from './change-password-dialog'

export default {
    components: {
        ChangePasswordDialog
    },

    mixins: [selectedTiktokBusinessMixin],

    props: ['total_balance'],

    data() {
        return {
            show_change_password_dialog: false,
            selected_language: this.p__list_select_languages[0],
            VUE_APP_ATOSA_TIKTOK_SERVER_STATIC:
                process.env.VUE_APP_ATOSA_TIKTOK_SERVER_STATIC,
            VUE_APP_ATOSA_FEEDBACK_FORM:
                process.env.VUE_APP_ATOSA_FEEDBACK_FORM,
            VUE_APP_ATOSA_FEEDBACK_FORM_EN:
                process.env.VUE_APP_ATOSA_FEEDBACK_FORM_EN
        }
    },

    computed: {
        s__website() {
            return this.$store.getters.app.s__website
        },

        display_name() {
            if (this.user_info) {
                return this.user_info.display_name
            }
            return 'User'
        },

        email() {
            if (this.user_info) {
                return this.user_info.email
            }
            return ''
        },

        user_info() {
            return this.$store.getters.user.s__user_info
        },

        tiktok_businesses() {
            return this.$store.getters.tiktokBusiness.s__tiktok_businesses || []
        },

        current_package() {
            return this.$store.getters.currentPackage.s__current_package
        },

        main_package() {
            return this.$store.getters.currentPackage.s__main_package
        },

        expiry() {
            if (this.current_package) {
                const license_time = moment(
                    this.current_package.license_to * 1000
                )

                return license_time.diff(moment()) > 0
            }

            return false
        }
    },

    mounted() {
        this.m__fetchTiktokBusinesses()

        const locale = this.$i18n.locale

        if (locale) {
            const temp = this.p__list_select_languages.find(
                (language) => language.code === locale
            )
            if (temp) {
                this.selected_language = temp
            }
        }
    },

    methods: {
        handleChangeTiktokBusiness(val) {
            localStorage.setItem('id_tiktok_business', val.id)
            this.$store.dispatch(
                'tiktokBusiness/s__setSelectedTiktokBusiness',
                val
            )
            setTimeout(() => {
                this.$router.go(0)
            }, 333)
        },

        handleLogOut() {
            this.$router.push({
                path: '/login'
            })
        },

        handleTranslate(code) {
            localStorage.setItem('code_lang', code)

            const url = window.location.href
            const urlParts = url.split('?')
            if (urlParts.length > 1) {
                const queryString = urlParts[1]
                const params = new URLSearchParams(queryString)

                if (params.has('lang')) {
                    params.delete('lang')
                    const newQueryString = params.toString()

                    const newURL =
                        urlParts[0] +
                        (newQueryString ? '?' + newQueryString : '')

                    window.history.replaceState({}, document.title, newURL)
                }
            }

            window.location.reload(true)
        }
    }
}
</script>
