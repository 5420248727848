const lang = {
    atosa_core: {
        description_form:
            'If you have a request for Atosa, please visit this form or contact Atosa directly',
        find_out_more: 'Learn more about ATOSA',
        message: {
            email_has_registered:
                'Email address has been used for registration before, please check and try again later',
            password_wrong:
                'The account or password is incorrect, please check and try again later',
            valid_email:
                'Invalid email format, please check and try again later',
            valid_phone:
                'Invalid phone number format, please check and try again later'
        }
    },
    button: {
        column_display: 'Column display',
        back: 'Back',
        create_campaign: 'Create campaign',
        choose: 'Choose',
        im_interested: "I'm interested",
        apply: 'Apply',
        back_dashboard: 'Back to Dashboard',
        buy_package: 'Buy package',
        choose_plan: 'Choose Plan',
        clear_all: 'Clear All',
        close: 'Close',
        confirm: 'Confirm',
        connect: 'Connect',
        continue: 'Continue',
        create: 'Create',
        create_again: 'Create again',
        custom_column: 'Custom column',
        delete: 'Delete',
        filters: 'Filters',
        log_out: 'Log Out',
        next: 'Next',
        reset: 'Reset',
        return: 'Return',
        sample_target_list: 'Sample target list',
        save_custom_target: 'Save template',
        save_custom_target_name: 'Save template_name',
        save_target: 'Save target',
        see_details: 'See details',
        see_more: 'See more',
        select_all: 'Select all',
        select_custom_target: 'Select template',
        send_request: 'Send request',
        skip: 'Skip',
        view_data: 'View data'
    },
    common: {
        number_zalo_to_noti: 'Zalo number to receive notifications',
        noti_channel: 'Notification channel',
        data_field: 'Data field',
        google_drive_path: 'Google drive path',
        file_name_google_sheet: 'File name Google sheet',
        create_configuration: 'Create configuration',
        google_account_verification: 'Google account verification',
        cpa_suggestion: 'CPA suggestion',
        integration_google: 'Interation Google sheet',
        desc_integration_google:
            'The feature will help you export advertising account data via Google Sheet daily',
        click_here: 'Click here',
        affiliate: 'Affiliate',
        title: 'Title',
        update: 'Update',
        save_item_success: 'Data saved successfully',
        this_month_last_year: 'This month last year',
        last_month_last_year: 'Last month last year',
        _1st_quarter: '1st quarter',
        _2nd_quarter: '2nd quarter',
        _3rd_quarter: '3rt quarter',
        _4th_quarter: '4th quarter',
        _1st_quarter_last_year: '1st quarter - last year',
        _2nd_quarter_last_year: '2nd quarter - last year',
        _3rd_quarter_last_year: '3rd quarter - last year',
        _4th_quarter_last_year: '4th quarter - last year',
        other: 'Other',
        app: 'App',
        level_xxx: 'Level {xxx}',
        add_screen_to_compare_audience:
            'Add audience chart screen , making comparsion easier',
        add_screen: 'Add screen',
        first_name: 'First name',
        last_name: 'Last name',
        number: 'Number',
        expire_month: 'Expire month',
        expire_year: 'Expire year',
        credit_card: 'Credit card',
        shop_ads: 'Shop ads',
        postal_code: 'Postal code',
        email_billing: 'Email to receive invoice',
        city: 'City',
        phone_number: 'Phone number',
        address: 'Address',
        recharge: 'Recharge',
        billing_address: 'Billing address',
        upload_file: 'Upload file',
        name_template: 'Name Template',
        start_time: 'Start time',
        end_time: 'End time',
        billing: 'Billing',
        text: 'Text',
        week: 'Week',
        adjust: 'Adjust',
        day_monday: 'Mon',
        day_tuesday: 'Tue',
        day_wednesday: 'Wed',
        day_thursday: 'Thu',
        day_friday: 'Fri',
        day_saturday: 'Sat',
        day_sunday: 'Sun',
        see_more: 'See more',
        deselect_all: 'Deselect all',
        unchecked: 'Unchecked',
        default: 'Default',
        form: 'Form',
        delete_all: 'Delete all',
        delete: 'Delete',
        stock: 'Stock',
        category: 'Category',
        tool: 'tool',
        ad_cannot_previewed_yet: 'This ad cannot be previewed yet',
        preview: 'Preview',
        overview: 'Overview',
        total_balance: 'Total Balance',
        manage_ad_group_budgets: 'Manage ad group budgets',
        budget_management: 'Budget management',
        new_name: 'New Name',
        old_name: 'Old name',
        batch_name_adjustment: 'Batch name adjustments',
        desc_create_campaign_by_atosa:
            'You should use an advertising account created from ATAME to set up a new campaign. This helps ATAME have more information and features to help optimize the campaign',
        desc_tiktok_business:
            'Tiktok business account is the account where you log in to Tiktok Ads. ATAME needs to link with a business Tiktok account to get information and data, helping you run ads easily',
        minus: 'Minus',
        desc_permission:
            'Using a secondary account makes managing and running TiKTok ads safer and easier to manage',
        system: 'System',
        replace: 'Replace',
        plus: 'Plus',
        Minus: 'Minus',
        batch_editing: 'Batch editing',
        no_suitable_ad_groups_bulk_budget_changes:
            'There are no suitable ad groups for bulk budget changes',
        batch_budget_adjustments: 'Batch budget adjustments',
        percent: 'Percent',
        new_budget: 'New Budget',
        detailed_analysis_none: 'Detailed analysis: None',
        detailed_analysis_day: 'Detailed analysis: Day',
        detailed_analysis_week: 'Detailed analysis: Week',
        detailed_analysis_month: 'Detailed analysis: Month',
        load_more: 'Load more',
        stop_after: 'Stop after',
        never_stop: 'Never stop',
        config: 'Config',
        config_auto_refresh: 'Automatically refresh configuration',
        refresh_second: 'Updated after each',
        edit_budget: 'Edit budget',
        unknown: 'Unknown',
        frozen: 'Frozen',
        havent_package: "You don't have a package yet",
        just_register_package: 'Sign up for the package here',
        change_password_success: 'Change password successfully',
        old_password: 'Old password',
        new_password: 'New password',
        change_password: 'Change password',
        feature_must_buy:
            'You must purchase a premium plan to use this feature',
        apply_package: 'Apply package',
        message_apply_package_success: 'Apply package successfully',
        quantity: 'Quantity',
        popular: 'Popular',
        feature: 'Feature',
        free: 'Free',
        add_to_target: 'Add the objects in the file to the list',
        remove_to_target: 'Remove the objects in the file from the list',
        replate_to_target: 'Replace the objects in the file into the list',
        delegate_permission_success: 'Delegate permissions successfully',
        delegate_permission_error: 'Delegate permissions failed',
        report_ads: 'Report Ads',
        manager_ads: 'Manager Ads',
        auto_ads: 'Auto Ads',
        delegate_permissions_for_second_account: 'Delegate permissions',
        confirm_password: 'Confirm password',
        password: 'Password',
        username: 'Username',
        edit_second_account: 'Edit second account',
        create_second_account: 'Create second account',
        second_account: 'Second account',
        last_access_time: 'Last access time',
        permission: 'Permission',
        transaction_details: 'Transaction Details',
        extra: 'Extra',
        save: 'Save',
        close_list: 'Close list',
        list: 'List',
        description_introduce_atodake:
            "* Click the 'I'm interested' button for more details about this product",
        introduce_atosaca:
            'ATOSACA - Multi-channel chat management software that supports sellers to manage Shopee and Tiktok messages. Integration with omnichannel sales management software',
        introduce_atodake:
            'You are having difficulty when the bill of lading between exchanges like Tiktok, Shopee, ... Atosa has launched ATODAKE product to overcome and optimize this problem.',
        end_datetime: 'End datetime',
        access_settings: 'Access Settings',
        action: 'Action',
        active: 'Active',
        ad: 'Ad',
        ad_account: 'Ad Account',
        ad_account_balance: 'Advertising account balance',
        ad_account_id: 'AD Account ID',
        ad_account_name: 'AD Account Name',
        ad_scheduling: 'Ad Scheduling',
        ad_type: 'AD type',
        adgroup: 'Ad Group',
        adjust_to: 'Adjust to',
        admin: 'Admin',
        ads_account: 'Ads Account',
        ads_manager: 'Ads Manager',
        advanced_settings: 'Advanced settings',
        advertiser_account: 'Advertiser Account',
        advertiser_accounts: 'Advertiser Accounts',
        advertising_fee: 'Advertising Fee',
        advertising_objective: 'Advertising objective',
        age: 'Age',
        age_and_gender: 'Age and Gender',
        all: 'All',
        all_active_ad: 'All active ads',
        all_active_adgroup: 'All active adgroups',
        all_active_campaign: 'All active campaigns',
        all_inactive_ad: 'All inactive ads',
        all_inactive_adgroup: 'All inactive adgroups',
        all_inactive_campaign: 'All inactive campaigns',
        an_error_has_occurred: 'An error has occurred',
        analyst: 'Analyst',
        and: 'And',
        asset: 'Asset',
        at_least: 'At least',
        audience: 'Audience',
        automatic_rule: 'Automatic rule',
        balance: 'Balance',
        balance_type: 'Balance type',
        basic: 'Basic',
        between: 'Between',
        budget: 'Budget',
        budget_mode_infinite: 'Infinite',
        business_center: 'Business Center',
        business_centers: 'Business Centers',
        call_to_action: 'Call to action',
        campaign: 'Campaign',
        campaigns: 'Campaigns',
        cash_amount: 'Cash Amount',
        catalog: 'Catalog',
        change: 'Change',
        click: 'Click',
        closed: 'Closed',
        company: 'Company',
        compare: 'Compare',
        completed: 'Completed',
        condition: 'Condition',
        confirm_linked: 'Confirm linked',
        connect_tiktok_account: 'Connect Tiktok Account',
        contains: 'Contains',
        content: 'Content',
        conversion: 'Conversion',
        copied: 'Copied',
        copy: 'Copy',
        copy_ad: 'Copy Ad',
        copy_adgroup: 'Copy Adgroup',
        cost: 'Cost',
        country: 'Country',
        create_data_success: 'Create data successfully',
        created_at: 'Created At',
        created_by: 'Created By',
        created_datetime: 'Created datetime',
        currency: 'Currency',
        current_ad_account_balance: 'Current AD Account Balance',
        daily: 'Daily',
        daily_budget: 'Daily Budget',
        dashboard: 'Dashboard',
        report_date: 'Date report',
        report_gmv: 'GMV MAX report',
        report_video: 'Video report',
        date: 'Date',
        date_range: 'Date Range',
        day: 'Day',
        days: 'Day(s)',
        days_since_creation: 'Days since creation',
        decrease: 'Decrease',
        delete_data_confirm: 'Do you want to delete this data?',
        delete_data_success: 'Delete data successfully',
        delete_success: 'Delete successfully',
        deleted: 'Deleted',
        description: 'Description',
        detailed_analysis: 'Detailed analysis',
        device: 'Device',
        disable: 'Disable',
        dynamic_daily_budget: 'Dynamic daily budget',
        edit: 'Edit',
        edit_name: 'Edit name',
        email_address: 'Email address',
        enable: 'Enable',
        end_with: 'Ends with',
        equal: 'Equal',
        error: 'Error',
        error_message: 'Error Message',
        exclude: 'Exclude',
        expire: 'Expired',
        export_excel: 'Export Excel',
        female: 'Female',
        finance: 'Finance',
        format_date: 'MM/DD/YYYY',
        format_fulldate: 'MM/DD/YYYY HH:mm',
        format_value_date_picker: 'MM-dd-yyyy',
        format_value_date_picker_moment: 'MM-DD-YYYY',
        format_value_date_picker_with_time: 'MM-dd-yyyy HH:mm',
        friday: 'Friday',
        gender: 'Gender',
        general: 'General',
        greater_than: 'Greater than',
        havent_connect_tiktok_shop:
            'You are not connected to Tiktok shop. If you want to run LIVE ads, please connect to your Tiktok shop so we can best support you.',
        hour: 'Hour',
        i_got_it: 'I got it',
        identity: 'Identity',
        if: 'If',
        impression: 'Impression',
        in_review: 'In review',
        inactive: 'Inactive',
        include: 'Include',
        increase: 'Increase',
        info: 'Info',
        invalid_period: 'Invalid period',
        language: 'Language',
        last_month: 'Last month',
        learn_more: 'Learn more',
        less_than: 'Less than',
        lifetime: 'Lifetime',
        lifetime_budget: 'Lifetime Budget',
        location: 'Location',
        male: 'Male',
        manage: 'Manage',
        management_accounts: 'Management Accounts',
        member: 'Member',
        members: 'Members',
        minute: 'Minute',
        monday: 'Monday',
        month: 'Month',
        name: 'Name',
        network: 'Network',
        never_show_popup: "Don't show this popup anymore",
        no_data: 'No Data',
        not_contains: "Doesn't contain",
        not_delivering: 'Not delivering',
        note: 'Note',
        object: 'Object',
        objective: 'Objective',
        objective_type: 'Advertiser Objective',
        operating_system: 'Operating System',
        operator: 'Operator',
        option: 'Option',
        optional: 'Optional',
        package: 'Package',
        page: 'Page',
        partners: 'Partners',
        past_xxx_days: 'Past {xxx} days',
        payment: 'Payment',
        performance: 'Performance',
        price: 'Price',
        product: 'Product',
        products: 'Products',
        province: 'Province',
        rank: 'Rank',
        reach: 'Reach',
        real_time: 'Real time',
        registered_area: 'Registered area',
        request_sent: 'Request sent',
        request_sent_success: 'Request sent successfully',
        result: 'Result',
        role: 'Role',
        rule: 'Rule',
        sample: 'Sample',
        saturday: 'Saturday',
        schedule: 'Schedule',
        second: 'Second',
        selected: 'Selected',
        setting: 'Setting',
        setup_auto: 'Setup Auto',
        shop: 'Shop',
        shops: 'Shops',
        show: 'Show',
        standard: 'Standard',
        start_with: 'Begins with',
        status: 'Status',
        string_equal: 'Is',
        success: 'Success',
        sunday: 'Sunday',
        suspended: 'Suspended',
        tax: 'Tax',
        then: 'Then',
        this_month: 'This month',
        this_week: 'This week',
        last_week: 'Last week',
        thursday: 'Thursday',
        tiktok_account: 'Tiktok Account',
        tiktok_accounts: 'Tiktok Accounts',
        tiktok_identity: 'Tiktok Identity',
        autobot: 'Auto Bot',
        tiktok_business: 'Tiktok business',
        tiktok_shops: 'Tiktok Shops',
        time: 'Time',
        timezone: 'Timezone',
        today: 'Today',
        total: 'Total',
        total_amount: 'Total amount',
        tracking: 'Tracking',
        traffic: 'Traffic',
        transaction_time: 'Transaction Time',
        transaction_type: 'Transaction Type',
        transfers: 'Recharge advertising account',
        tuesday: 'Tuesday',
        turn: 'Turn',
        turn_off: 'Turn off',
        turn_on: 'Turn on',
        update_data_confirm: 'Do you want to update this data?',
        update_data_success: 'Update data successfully',
        using: 'Using',
        value: 'Value',
        video_play: 'Video play',
        view: 'View',
        warning: 'Warning',
        website: 'Website',
        wednesday: 'Wednesday',
        xxx_has_plural_noun: '{xxx}(s)',
        xxx_id: '{xxx} ID',
        xxx_status: '{xxx} Status',
        year: 'Year',
        yesterday: 'Yesterday',
        you_can_create_up_to_bc: 'You can create up to {number} BCs',
        your_account_balance: 'Your Account Balance'
    },
    date_picker: {
        options: {
            last_xxx_days: 'Last {xxx} days'
        },
        placeholder: {
            end: 'To Date',
            start: 'Start Date'
        }
    },
    error: {
        cannot_access: 'Cannot Access this app'
    },
    input: {
        placeholder: {
            amount_must_at_least: 'Amount must be at least {value}',
            custom_must_at_least: '{name} must be at least {value}',
            please_input: 'Please input',
            please_select: 'Please select',
            search_by_name_or_id: 'Search by name or ID',
            select_ads_account: 'Select Advertiser Account',
            select_campaign: 'Select Campaign',
            select_all: 'Default select all',
            select_business_center: 'Select Business Center',
            select_tiktok_account: 'Select Tiktok Business',
            value_must_at_least: 'Value must be at least {value}',
            value_must_gte: 'Value must be greater than {value}'
        },
        rule: {
            not_empty: 'Not empty'
        }
    },
    message: {
        close_wont_be_save: "Your progress won't be saved.",
        expired_package:
            'Your <span class="font-semibold">{name}</span> package has expired from <span class="font-semibold">{license_to}</span> date. Please renew the product package to use.',
        xxx_fail: '{xxx} failed',
        xxx_success: '{xxx} success'
    },
    page: {
        billing: {
            desc_billing_address:
                'These are the addresses you can use for us to send invoices'
        },
        dashboard: {
            overview_ads_xxx: "Overview of <b>{xxx}</b>'s advertising data",
            spend_overview_lifetime: 'Lifetime cost',
            impressions_overview_lifetime: 'Lifetime impressions',
            conversion_overview_lifetime: 'Lifetime conversions',
            spend_overview_today: 'Cost today',
            impressions_overview_today: 'Impressions today',
            conversion_overview_today: 'Conversions today'
        },
        admin: {
            cash_amount: 'Cash amount',
            fee_amount: 'Tax for contractors',
            payment_history: 'Payment history',
            recharge_history: 'Recharge history',
            total_cash_amount: 'Total cash amount'
        },
        authen_tiktok_account: {
            desc: 'Please wait a few minutes. If the verification takes too long, you can refresh the website'
        },
        campaign: {
            ad_entity_type: 'Popular advertising model',
            in_xxx_campaigns: 'In {xxx} campaigns',
            in_xxx_adgroups: 'In {xxx} ad groups',
            choose_product_from: 'Choose products from',
            contact_atosa:
                'If you need to run Tiktok ads or want advice to improve campaign results, please contact ATOSA',
            historical_sales: 'Historical sales',
            view_mode: 'View mode',
            campaign_status_tiktok_post_hesitate_recommend: 'Public',
            campaign_status_tiktok_post_only_friend_see: 'Friends',
            campaign_status_tiktok_post_only_author_see: 'Hidden',
            bid_strategy_minimum_roas: 'Minimum ROAS',
            bid_strategy_gmv: 'Highest gross revenue',
            bid_strategy_highest_value: 'Highest value',
            bid_strategy_max_conversion: 'Maximum delivery',
            instant_form: 'Instant open form',
            dpa_target_audience_type_prospecting:
                'Search for potential customers',
            dpa_target_audience_type_retargeting: 'Retargeting audience',
            get_target: 'Target',
            dpa_target_audience_type: 'Sales category object',
            adjust_batch_names_according_to_name_template:
                'Adjust batch names according to the name template',
            upload_video_success: 'Download video successfully',
            desc_upload_video_1:
                '<b>Recommended format</b>: .mp4, .mov, .mpeg or .avi',
            desc_upload_video_2: '<b>File size</b>: 500 MB',
            desc_upload_video_3:
                '<b>Aspect ratio requirements for all positions</b>: Horizontal(16:9) / Square(1:1) / Vertical(9:16)',
            desc_upload_video_4:
                '<b>For TikTok</b>: Resolution Horizontal(960*540) / Square(640*640) / Vertical(540*960) / Bitrate ≥ 516.00 Kbpskbps / Duration 5-60s / Region safe From left: 44px, From right: 140px, From above: 130px, From bottom: 483-484px',
            desc_upload_video_5: 'See recommended video specifications',
            cannot_use_file: 'File file could not use',
            edit_name_template_successfully:
                'Edited name template successfully',
            add_field: 'Add field',
            add_fields_to_form: 'Add fields to form template',
            field_separator: 'Field separator',
            created_name_template_successfully:
                'Created name template successfully',
            process_take_a_few_minutes:
                'This process will take a few minutes. Please wait and keep the screen intact so the process can complete',
            adjust_end_time_to_xxx_from_start_time:
                'Adjust the end time to {xxx} from the start time',
            spu_id_or_product_name: 'SPU ID or product name',
            ad_delivery_schedule: 'Ad delivery schedule',
            distributed_all_day: 'Distributed all day',
            ads_will_be_delivered_all_day_long: 'Ads will be delivered all day',
            ad_delivery_time_frame:
                'Ads will be delivered in the following time frames',
            all_products: 'All Products',
            desc_all_products:
                "Show people the products they're most likely to purchase from what's available in your shop",
            specific_products: 'Specific Products',
            desc_specific_products:
                "Show people the products they're most likely to purchase from one or more products you select from your shop",
            add_plan: 'Add plan',
            budget_adjustment_plan: 'Budget adjustment plan',
            desc_budget_adjustment_plan:
                'ATAME will adjust the budget according to the timelines you set, helping you automate and optimize advertising',
            total_daily_budget: 'Total daily budget',
            total_lifetime_budget: 'Total lifetime budget',
            desc_product:
                'Only available products can be selected to deliver ads. This includes reviewed products with sufficient inventory',
            product_shopping_ads: 'Product Shopping Ads',
            desc_product_shopping_ads:
                'Use product visuals to promote products in Shopping Center',
            desc_product_shopping_ads_2:
                'Only available in Indonesia, Malaysia, Thailand, Philippines and Vietnam',
            message_dont_choose_interest_keyword_ids:
                'The object to save to the file has not been selected yet',
            desc_warning_add_balance:
                'You need to deposit more money for the campaign to work at its best, avoiding the case that the campaign is turned off when it exceeds the budget.',
            desc_optimization_goal_click:
                'Maximize the number of clicks to achieve the lowest cost per click.',
            desc_optimization_goal_viewer_retention:
                'Maximize the number of times viewers watched your LIVE for more than 10 seconds.',
            desc_optimization_goal_product_click_in_live:
                'Maximize the number of clicks on your products during your LIVE.',
            desc_optimization_goal_initiate_checkout:
                'Maximize the number of submitted orders to achieve the lowest cost per order.',
            desc_optimization_goal_complete_payment:
                'Maximize the number of "Buy now" and "Buy with coupon" clicks on your products, or "Check out" actions.',
            optimization_goal_gmv: 'Gross revenue',
            desc_optimization_goal_gmv:
                'Maximise your gross revenue to achieve the highest possible return on ad spend.',
            edit_rule: 'Edit Rule',
            message_start_time_less_than_or_equal_end_time_in_specified_time_period_automated_rule:
                'The start time must be less than or equal to the end time in the specified time period',
            message_havent_select_day_in_specified_time_period_automated_rule:
                "Haven't selected a date in a specific time period",
            message_overlap_specified_time_period_automated_rule:
                'Specific time periods overlap',
            set_end_time: 'Change the end time of an ad group',
            pacing_smooth_to_fast:
                'Switch the standard distribution to acceleration',
            pacing_fast_to_smooth:
                'Convert accelerated distribution to standard',
            automated_rule_create_custom_rule: 'Create custom rule',
            automated_rule_description_create_custom_rule:
                'Create a rule without a template',
            automated_rule_schedule_delivery: 'Schedule delivery',
            automated_rule_description_schedule_delivery:
                'Turn on/off your ad at a specific time',
            automated_rule_control_budget: 'Control Budget',
            automated_rule_description_control_budget:
                'Turn on/off ads with unexpected high costs',
            description_column_reach:
                'The number of unique users who saw your ads at least once. This metric is estimated.',
            description_column_result_rate:
                'The percentage of results you achieved out of all of the views/clicks on your ads. As one campaign may have a number of different optimization goals, this statistic is not supported for campaigns. Please go to ad groups or ads to view the result rate.<br/>The total count is calculated based on the time each ad impression occurred.',
            description_column_cost_per_result:
                'The average cost for each result from your ads. As one campaign may have a number of different optimization goals, this statistic is not supported for campaigns. Please go to ad groups or ads to view the cost per result.<br/>The total count is calculated based on the time each ad impression occurred.',
            description_column_result:
                'The number of times your ad achieved an outcome, based on the optimization goal you selected. As one campaign may have a number of different optimization goals, this statistic is not supported for campaigns. Please go to ad groups or ads to view the results.<br/>The total count is calculated based on the time each ad impression occurred.',
            description_column_spend:
                "The estimated total amount of money you've spent on your campaign, ad group, or ad during its schedule",
            description_column_cpc:
                "The average amount of money you've spent on a click",
            description_column_impressions:
                'The number of times your ads were on screen',
            description_column_cpm:
                "The average amount of money you've spent per 1,000 impressions",
            description_column_cost_per_conversion:
                "The average amount of money you've spent on a conversion.<br/>The total count is calculated based on the time each ad impression occurred",
            description_column_clicks: 'The number of clicks on your ads',
            description_column_conversion_rate:
                'The number of times your ad achieved an outcome based on the objective and settings you selected.<br/>The total count is calculated based on the time each ad impression occurred',
            description_column_ctr:
                'The percentage of times people saw your ad and performed a click',
            description_column_conversion:
                'The number of times your ad achieved an outcome based on the objective and settings you selected.<br/>The total count is calculated based on the time each ad impression occurred',
            accelerated: 'Accelerated',
            action_auto: 'Auto',
            action_bid: 'Budget',
            action_frequency_cap: 'Action frequency cap',
            action_pause: 'Pause',
            ad_creative: 'Ad creative',
            ad_details: 'Ad Details',
            ad_format: 'Ad Format',
            ad_list: 'Ad list',
            ad_setup: 'Ad setup',
            ad_status_adgroup_is_closed: 'Ad group is closed',
            ad_status_music_copyright_missing: 'Music copyright missing',
            ad_status_processing_audio_reupload_if_it_gets_stuck:
                'Processing audio, re-upload if it gets stuck',
            ad_status_replace_videos_with_errors_in_the_adgroup:
                'Replace videos with errors in the ad group',
            add_condition: 'Add Condition',
            add_action: 'Add Action',
            add_config: 'Add Config',
            add_products: 'Add Products',
            add_time: 'Add time',
            adgroup_list: 'Adgroup list',
            adgroup_pane: {
                add_copies_to: 'Add copies to',
                edit_ad: 'Edit Ad',
                edit_adgroup: 'Edit Ad group',
                edit_campaign: 'Edit Campaign',
                number_copies: 'Number of Copies'
            },
            adgroup_status_account_penalized: 'Account penalized',
            adgroup_status_ad_group_out_of_budget: 'Ad group out of budget',
            adgroup_status_adgroup_deleted: 'Ad group deleted',
            adgroup_status_adgroup_inactive: 'Ad group inactive',
            adgroup_status_frozen: 'Ad group frozen',
            adgroup_status_asset_unavailable_or_authorization_revoked:
                'Asset unavailable or authorization revoked',
            adgroup_status_campaign_deleted: 'Campaign deleted',
            adgroup_status_campaign_inactive: 'Campaign inactive',
            adgroup_status_contract_has_not_taken_effect:
                'Contract has not taken effect',
            adgroup_status_disapproved: 'Disapproved',
            adgroup_status_edited_for_review: 'Edited for review',
            adgroup_status_expired: 'Expired',
            adgroup_status_in_review: 'In review',
            adgroup_status_LIVE_not_started: 'LIVE not started',
            adgroup_status_no_creatives: 'No creatives',
            adgroup_status_not_approved: 'Not approved',
            adgroup_status_outside_of_schedule:
                'The scheduled delivery time has not started',
            adgroup_status_payment_unsuccessful_or_insufficient_balance:
                'Payment unsuccessful or insufficient balance',
            adgroup_status_pixel_unauthorized: 'Pixel Unauthorized',
            adgroup_status_qualification_needed: 'Qualification needed',
            adgroup_status_unable_to_convert_video_upload_again:
                'Unable to convert video, upload again',
            adjust_bid: 'Adjust Bid',
            adjust_daily_budget: 'Adjust Daily budget',
            adjust_lifetime_budget: 'Adjust Lifetime budget',
            all_active_ad_under_xxx_adgroup_has_selected:
                'Active ads in {xxx} adgroups',
            all_active_ad_under_xxx_campaign_has_selected:
                'Active ads in {xxx} campaigns',
            all_active_adgroup_under_xxx_campaign_has_selected:
                'Active adgroups in {xxx} campaigns',
            all_day: 'All day',
            all_inactive_ad_under_xxx_adgroup_has_selected:
                'Inactive ads in {xxx} adgroups',
            all_inactive_ad_under_xxx_campaign_has_selected:
                'Inactive ads in {xxx} campaigns',
            all_inactive_adgroup_under_xxx_campaign_has_selected:
                'Inactive adgroups in {xxx} campaigns',
            all_objects: 'All objects',
            also_notify_me: 'Also notify me if conditions aren’t met',
            any_price: 'Any price',
            app_promotion: 'App promotion',
            apply_rule: 'Apply rule to',
            auto_ads: 'Auto Ads',
            auto_max_cost: 'Max Cost',
            auto_max_cost_action: 'Action when cost is exceeded',
            auto_max_cost_bid: 'Budget',
            auto_max_cpa: 'Max CPA',
            auto_min_cpa: 'Min CPA',
            auto_refresh: 'Auto Refresh',
            automatic_cpa_optimization: 'Automatic Ads Optimization By CPA',
            automatic_live_optimization: 'Automatic Live Campaign Optimization',
            automatic_targeting: 'Automatic targeting',
            available_audience: 'Available audience',
            awareness: 'Awareness',
            belong_to_atosa: 'Belong to Atosa',
            bid: 'Bid',
            bid_control: 'Bid control',
            bid_strategy: 'Bid strategy',
            bid_strategy_bid_cap: 'Bid cap',
            bid_strategy_cost_cap: 'Cost cap',
            bid_strategy_lowest_cost: 'Lowest cost',
            bidding_optimization: 'Bidding & Optimization',
            billing_event: 'Billing event',
            budget_total_rate: 'Budget Rate',
            calculation_method: 'Calculation method',
            call_to_action_apply_now: 'Apply now',
            call_to_action_book_now: 'Book now',
            call_to_action_contact_us: 'Contact us',
            call_to_action_download_now: 'Download now',
            call_to_action_experience_now: 'Experience now',
            call_to_action_get_quote: 'Get quote',
            call_to_action_get_showtimes: 'Get showtimes',
            call_to_action_get_tickets_now: 'Get tickets now',
            call_to_action_install_now: 'Install now',
            call_to_action_interested: 'Interested',
            call_to_action_join_this_hastag: 'Join this hastag',
            call_to_action_learn_more: 'Learn more',
            call_to_action_listen_now: 'Listen now',
            call_to_action_order_now: 'Order now',
            call_to_action_play_game: 'Play game',
            call_to_action_preorder_now: 'Preorder now',
            call_to_action_read_more: 'Read more',
            call_to_action_shoot_with_this_effect: 'Shooting with this effect',
            call_to_action_shop_now: 'Shop now',
            call_to_action_sign_up: 'Sign up',
            call_to_action_subscribe: 'Subscribe',
            call_to_action_view_now: 'View now',
            call_to_action_view_profile: 'View profile',
            call_to_action_view_video_with_this_effect:
                'View video with this effect',
            call_to_action_visit_store: 'Visit store',
            call_to_action_watch_live: 'Watch live',
            call_to_action_watch_now: 'Watch now',
            campaign_budget_optimization: 'Campaign budget optimization',
            campaign_name: 'Campaign Name',
            campaign_status_account_in_review: 'Account in review',
            campaign_status_account_not_approved: 'Account not approved',
            campaign_status_account_penalized: 'Account penalized',
            campaign_status_campaign_out_of_budget: 'Campaign out of budget',
            campaign_status_contract_has_not_taken_effect:
                'Contract has not taken effect',
            carriers: 'Carriers',
            isp: 'Internet service provider',
            catalog_sales: 'Catalog sales',
            click_tracking_url: 'Click tracking URL',
            column_achieve_level: 'Unique Achieve Level',
            column_achieve_level_rate: 'Achieve Level Rate (%)',
            column_add_billing: 'Add Billing',
            column_add_billing_rate: 'Add Billing Rate (%)',
            column_add_payment_info: 'Unique Add Payment Info',
            column_add_payment_info_rate: 'Add Payment Info Rate (%)',
            column_add_to_wishlist: 'Unique Add to Wishlist',
            column_add_to_wishlist_rate: 'Add to Wishlist Rate (%)',
            column_anchor_click_rate: 'Anchor Click Rate',
            column_anchor_clicks: 'Anchor Clicks',
            column_app_event_add_to_cart: 'Unique Add to Cart',
            column_app_event_add_to_cart_rate: 'Unique Add to Cart Rate (%)',
            column_app_install: 'App Install',
            column_average_video_play:
                'Video Average Watch Time Per Video View',
            column_average_video_play_per_user:
                'Video Average Watch Time Per Person',
            column_avg_value_per_pageview: 'Value per Page View',
            column_button_click: 'Button Click',
            column_button_click_rate: 'Button Click Rate (%)',
            column_checkout: 'Unique Checkout',
            column_checkout_rate: 'Unique Checkout Rate (%)',
            column_clicks: 'Clicks',
            column_clicks_on_hashtag_challenge: 'Clicks on hashtag challenge',
            column_clicks_on_music_disc: 'Music Clicks',
            column_comments: 'Paid Comments',
            column_complete_payment: 'Total Complete Payment',
            column_complete_payment_rate: 'Complete Payment Rate (%)',
            column_complete_payment_roas: 'Complete Payment ROAS',
            column_complete_tutorial: 'Unique Complete Tutorial',
            column_complete_tutorial_rate: 'Complete Tutorial Rate (%)',
            column_conversion: 'Conversions',
            column_conversion_rate: 'CVR',
            column_cost_per_1000_reached: 'Cost per 1,000 People Reached',
            column_cost_per_achieve_level: 'Cost per Unique Achieve Level',
            column_cost_per_add_billing: 'Add Billing Cost',
            column_cost_per_add_payment_info:
                'Cost per Unique Add Payment Info',
            column_cost_per_add_to_wishlist: 'Cost per Unique Add to Wishlist',
            column_cost_per_app_event_add_to_cart:
                'Cost per Unique Add to Cart',
            column_cost_per_app_install: 'Cost per App Install',
            column_cost_per_button_click: 'Cost per Button Click',
            column_cost_per_checkout: 'Cost per Unique Checkout',
            column_cost_per_complete_payment: 'Cost per Complete Payment',
            column_cost_per_complete_tutorial:
                'Cost per Unique Complete Tutorial',
            column_cost_per_conversion: 'CPA',
            column_cost_per_create_gamerole: 'Cost per Unique Create Role',
            column_cost_per_create_group: 'Cost per Unique Create Group',
            column_cost_per_cta_app_install: 'Cost per CTA App Install',
            column_cost_per_cta_conversion: 'Cost per CTA Conversion',
            column_cost_per_cta_purchase: 'Cost per CTA Purchase',
            column_cost_per_cta_registration: 'Cost per CTA Registration',
            column_cost_per_custom_app_event: 'Cost per Custom App Event',
            column_cost_per_custom_page_event: 'Cost per Custom Page Event',
            column_cost_per_download_start: 'Cost per Download',
            column_cost_per_form: 'Cost per Submit Form',
            column_cost_per_in_app_ad_click: 'Cost per Unique In-App Ad Click',
            column_cost_per_in_app_ad_impr: 'Cost per Unique In-App Ad Impr',
            column_cost_per_initiate_checkout: 'Cost per Initiate Checkout',
            column_cost_per_ix_button_click_count:
                'Cost per Call-to-Action Button Clicks (Onsite)',
            column_cost_per_ix_page_view_count: 'Cost per Page Views (Onsite)',
            column_cost_per_ix_product_click_count:
                'Cost per Product Clicks (Onsite)',
            column_cost_per_join_group: 'Cost per Unique Join Group',
            column_cost_per_landing_page_view: 'Cost per Landing Page View',
            column_cost_per_launch_app: 'Cost per Unique Launch App',
            column_cost_per_loan_apply: 'Cost per Unique Loan Apply',
            column_cost_per_loan_credit: 'Cost per Unique Loan Approval',
            column_cost_per_loan_disbursement:
                'Cost per Unique Loan Disbursement',
            column_cost_per_login: 'Cost per Unique Login',
            column_cost_per_next_day_open: 'Cost per Unique Day 1 Retention',
            column_cost_per_offline_contact_event: 'Cost per Contact (Offline)',
            column_cost_per_offline_form_event:
                'Cost per Submit Form (Offline)',
            column_cost_per_offline_shopping_event:
                'Cost per Complete Payment (Offline)',
            column_cost_per_offline_subscribe_event:
                'Cost per Subscribe (Offline)',
            column_cost_per_on_web_add_to_wishlist: 'Cost per Add To Wishlist',
            column_cost_per_on_web_order: 'Cost per Place an Order',
            column_cost_per_on_web_subscribe: 'Subscribe Cost',
            column_cost_per_online_consult: 'Cost per Contact',
            column_cost_per_onsite_add_billing: 'Cost per Add Billing (Onsite)',
            column_cost_per_onsite_add_to_wishlist:
                'Cost per Add To Wishlist (Onsite)',
            column_cost_per_onsite_download_start:
                'Cost per App Store Click (Onsite)',
            column_cost_per_onsite_form: 'Cost per Form Submission (Onsite)',
            column_cost_per_onsite_initiate_checkout_count:
                'Cost per Initiate Checkout (Onsite)',
            column_cost_per_onsite_on_web_cart: 'Cost per Add to Cart (Onsite)',
            column_cost_per_onsite_on_web_detail:
                'Cost per Product Details Page View (Onsite)',
            column_cost_per_onsite_shopping:
                'Cost per Complete Payment (Onsite)',
            column_cost_per_page_browse_view: 'Cost per Page Browse',
            column_cost_per_page_event_search: 'Search Cost',
            column_cost_per_pageview: 'Cost per Page View',
            column_cost_per_product_details_page_browse:
                'Product Details Page Browse Cost',
            column_cost_per_purchase: 'Cost per Unique Purchase',
            column_cost_per_ratings: 'Cost per Unique Rate',
            column_cost_per_registration: 'Cost per Unique Registration',
            column_cost_per_result: 'Cost per result',
            column_cost_per_sales_lead: 'Cost per Unique Generate Lead',
            column_cost_per_search: 'Cost per Unique Search',
            column_cost_per_spend_credits: 'Cost per Unique Spend Credit',
            column_cost_per_start_trial: 'Cost per Unique Start Trial',
            column_cost_per_subscribe: 'Cost per Unique Subscribe',
            column_cost_per_total_achieve_level: 'Cost per Achieve Level',
            column_cost_per_total_add_to_wishlist: 'Cost per Add to Wishlist',
            column_cost_per_total_app_event_add_to_cart: 'Cost per Add to Cart',
            column_cost_per_total_checkout: 'Cost per Checkout',
            column_cost_per_total_complete_tutorial:
                'Cost per Complete Tutorial',
            column_cost_per_total_create_gamerole: 'Cost per Create Role',
            column_cost_per_total_create_group: 'Cost per Create Group',
            column_cost_per_total_in_app_ad_click: 'Cost per In-App Ad Click',
            column_cost_per_total_in_app_ad_impr: 'Cost per In-App Ad Impr',
            column_cost_per_total_join_group: 'Cost per Join Group',
            column_cost_per_total_launch_app: 'Cost per Launch App',
            column_cost_per_total_loan_apply: 'Cost per Loan Apply',
            column_cost_per_total_loan_credit: 'Cost per Loan Approval',
            column_cost_per_total_loan_disbursement:
                'Cost per Loan Disbursement',
            column_cost_per_total_login: 'Cost per Login',
            column_cost_per_total_next_day_open: 'Cost per Day 1 Retention',
            column_cost_per_total_purchase: 'Cost per Purchase',
            column_cost_per_total_ratings: 'Cost per Rate',
            column_cost_per_total_registration: 'Cost per Registration',
            column_cost_per_total_sales_lead: 'Cost per Generate Lead',
            column_cost_per_total_search: 'Cost per Search',
            column_cost_per_total_spend_credits: 'Cost per Spend Credit',
            column_cost_per_total_start_trial: 'Cost per Start Trial',
            column_cost_per_total_subscribe: 'Cost per Subscribe',
            column_cost_per_total_unlock_achievement:
                'Cost per Unlock Achievement',
            column_cost_per_total_view_content: 'Cost per View Content',
            column_cost_per_unique_custom_app_event:
                'Cost per Unique Custom App Event',
            column_cost_per_unlock_achievement:
                'Cost per Unique Unlock Achievement',
            column_cost_per_user_registration: 'Cost per Complete Registration',
            column_cost_per_view_content: 'Cost per Unique View Content',
            column_cost_per_vta_app_install: 'Cost per VTA App Install',
            column_cost_per_vta_conversion: 'Cost per VTA Conversion',
            column_cost_per_vta_purchase: 'Cost per VTA Purchase',
            column_cost_per_vta_registration: 'Cost per VTA Registration',
            column_cost_per_web_event_add_to_cart: 'Cost per Add to Cart',
            column_cost_total_add_payment_info: 'Cost per Add Payment Info',
            column_countdown_sticker_recall_clicks:
                'Countdown Sticker Recall Clicks',
            column_cpc: 'CPC',
            column_cpm: 'CPM',
            column_create_gamerole: 'Unique Create Role',
            column_create_gamerole_rate: 'Unique Create Role Rate (%)',
            column_create_group: 'Unique Create Group',
            column_create_group_rate: 'Create Group Rate (%)',
            column_cta_app_install: 'CTA App Install',
            column_cta_conversion: 'CTA Conversions',
            column_cta_purchase: 'CTA Purchase',
            column_cta_registration: 'CTA Registration',
            column_ctr: 'CTR',
            column_custom_app_event_rate: 'Custom App Event Rate (%)',
            column_custom_app_events: 'Total Custom App Event',
            column_custom_app_events_value: 'Total Custom App Event Value',
            column_custom_page_event_rate: 'Custom Page Event Rate (%)',
            column_custom_page_events: 'Total Custom Page Event',
            column_custom_page_events_value: 'Total Custom Page Event Value',
            column_download_start: 'Total Download',
            column_download_start_rate: 'Download Rate (%)',
            column_duet_clicks: 'Duet Clicks',
            column_engaged_view: '6-second views (Focused view)',
            column_engaged_view_15s: '15-second views (Focused view)',
            column_engagement_rate: 'Engagement Rate',
            column_engagements: 'Total Engagement',
            column_follows: 'Paid Followers',
            column_form: 'Total Submit Form',
            column_form_rate: 'Submit Form Rate (%)',
            column_frequency: 'Frequency',
            column_impressions: 'Impressions',
            column_in_app_ad_click: 'Unique In-App Ad Click',
            column_in_app_ad_click_rate: 'In-App Ad Click Rate (%)',
            column_in_app_ad_impr: 'Unique In-App Ad Impr',
            column_in_app_ad_impr_rate: 'In-App Ad Impr Rate (%)',
            column_initiate_checkout: 'Total Initiate Checkout',
            column_initiate_checkout_rate: 'Initiate Checkout Rate (%)',
            column_interactive_add_on_activity_clicks:
                'Interactive Add-on Activity Clicks',
            column_interactive_add_on_destination_clicks:
                'Interactive Add-on Destination Clicks',
            column_interactive_add_on_impressions:
                'Interactive Add-on Impressions',
            column_interactive_add_on_option_a_clicks:
                'Interactive Add-on Option A Clicks',
            column_interactive_add_on_option_b_clicks:
                'Interactive Add-on Option B Clicks',
            column_ix_average_video_play:
                'Average View Time per Instant Experience Video Component',
            column_ix_button_click_count:
                'Call-to-Action Button Clicks (Instant Experience)',
            column_ix_button_click_count_rate:
                'Call-to-Action Button Clicks Rate (Onsite) (%)',
            column_ix_page_duration_avg: 'Instant Experience Average View Time',
            column_ix_page_view_count: 'Page Views (Onsite)',
            column_ix_page_view_count_rate: 'Page Views Rate (Onsite) (%)',
            column_ix_page_viewrate_avg:
                'Instant Experience Average View Percentage',
            column_ix_product_click_count:
                'Product Clicks (Instant Experience)',
            column_ix_product_click_count_rate:
                'Product Clicks Rate (Onsite) (%)',
            column_ix_video_views: 'Instant Experience Video Component Views',
            column_ix_video_views_p100:
                'Instant Experience Video Component Views at 100%',
            column_ix_video_views_p25:
                'Instant Experience Video Component Views at 25%',
            column_ix_video_views_p50:
                'Instant Experience Video Component Views at 50%',
            column_ix_video_views_p75:
                'Instant Experience Video Component Views at 75%',
            column_join_group: 'Unique Join Group',
            column_join_group_rate: 'Join Group Rate (%)',
            column_landing_page_view_rate: 'Landing Page View Rate',
            column_launch_app: 'Unique Launch App',
            column_launch_app_rate: 'Launch App Rate (%)',
            column_likes: 'Paid Likes',
            column_live_effective_views: 'Effective LIVE Views',
            column_live_product_clicks: 'LIVE Product Clicks',
            column_live_unique_views: 'LIVE Unique Views',
            column_live_views: 'LIVE Views',
            column_loan_apply: 'Unique Loan Apply',
            column_loan_apply_rate: 'Loan Apply Rate (%)',
            column_loan_credit: 'Unique Loan Approval',
            column_loan_credit_rate: 'Loan Approval Rate (%)',
            column_loan_disbursement: 'Unique Loan Disbursement',
            column_loan_disbursement_rate: 'Loan Disbursement Rate (%)',
            column_login: 'Unique Login',
            column_login_rate: 'Login Rate (%)',
            column_next_day_open: 'Unique Day 1 Retention',
            column_next_day_open_rate: 'Day 1 Retention Rate (%)',
            column_offline_contact_event_rate: 'Contact Rate (Offline) (%)',
            column_offline_contact_events: 'Total Contact (Offline)',
            column_offline_contact_events_value:
                'Total Contact Value (Offline)',
            column_offline_form_event_rate: 'Submit Form Rate (Offline) (%)',
            column_offline_form_events: 'Total Submit Form (Offline)',
            column_offline_form_events_value:
                'Total Submit Form Value (Offline)',
            column_offline_shopping_event_rate:
                'Complete Payment Rate (Offline) (%)',
            column_offline_shopping_events: 'Total Complete Payment (Offline)',
            column_offline_shopping_events_roas:
                'Complete Payment ROAS (Offline)',
            column_offline_shopping_events_value:
                'Total Complete Payment Value (Offline)',
            column_offline_subscribe_event_rate: 'Subscribe Rate (Offline) (%)',
            column_offline_subscribe_events: 'Total Subscribe (Offline)',
            column_offline_subscribe_events_value:
                'Total Subscribe Value (Offline)',
            column_on_web_add_to_wishlist: 'Total Add to Wishlist',
            column_on_web_add_to_wishlist_per_click: 'Add To Wishlist Rate',
            column_on_web_order: 'Total Place an Order',
            column_on_web_order_rate: 'Place an Order Rate (%)',
            column_on_web_subscribe: 'Total Subscribe',
            column_on_web_subscribe_per_click: 'Subscribe Rate (%)',
            column_online_consult: 'Contact',
            column_online_consult_rate: 'Contact Rate (%)',
            column_onsite_add_billing: 'Total Add Billing (Onsite)',
            column_onsite_add_billing_rate: 'Add Billing Rate (Onsite) (%)',
            column_onsite_add_to_wishlist: 'Total Add To Wishlist (Onsite)',
            column_onsite_add_to_wishlist_rate:
                'Add To Wishlist Rate (Onsite) (%)',
            column_onsite_download_start: 'Total App Store Click (Onsite)',
            column_onsite_download_start_rate:
                'App Store Click Rate (Onsite) (%)',
            column_onsite_form: 'Total Form Submission (Onsite)',
            column_onsite_form_rate: 'Form Submission Rate (Onsite) (%)',
            column_onsite_initiate_checkout_count: 'Checkouts initiated (Shop)',
            column_onsite_initiate_checkout_count_rate:
                'Initiate Checkout Rate (Onsite) (%)',
            column_onsite_on_web_cart: 'Total Add to Cart (Onsite)',
            column_onsite_on_web_cart_rate: 'Add to Cart Rate (Onsite) (%)',
            column_onsite_on_web_detail: 'Product page views (Shop)',
            column_onsite_on_web_detail_rate:
                'Product Details Page View Rate (Onsite) (%)',
            column_onsite_shopping: 'Purchases (Shop)',
            column_onsite_shopping_rate: 'Complete Payment Rate (Onsite) (%)',
            column_onsite_shopping_roas: 'ROAS (Shop)',
            column_page_browse_view: 'Page Browse',
            column_page_browse_view_rate: 'Page Browse Rate (%)',
            column_page_event_search: 'Search',
            column_page_event_search_rate: 'Search Rate (%)',
            column_pageview_rate: 'Page View Rate (%)',
            column_product_details_page_browse: 'Product Details Page Browse',
            column_product_details_page_browse_rate:
                'Product Details Page Browse Rate (%)',
            column_profile_visits: 'Paid Profile Visit',
            column_profile_visits_rate: 'Paid Profile Visit Rate',
            column_purchase: 'Unique Purchase',
            column_purchase_rate: 'Purchase Rate (%)',
            column_ratings: 'Unique Rate',
            column_ratings_rate: 'Rate Rate (%)',
            column_reach: 'Reach',
            column_real_time_app_install: 'Real-time App Install',
            column_real_time_app_install_cost: 'Cost per Real-time App Install',
            column_real_time_conversion: 'Real-time conversions',
            column_real_time_conversion_rate: 'Real-time CVR',
            column_real_time_cost_per_conversion: 'Real-time CPA',
            column_real_time_cost_per_result: 'Real-time cost per result',
            column_real_time_result: 'Real-time result',
            column_real_time_result_rate: 'Real-time result rate',
            column_registration: 'Unique Registration',
            column_registration_rate: 'Registration Rate (%)',
            column_result: 'Result',
            column_result_rate: 'Result rate',
            column_sales_lead: 'Unique Generate Lead',
            column_sales_lead_rate: 'Generate Lead Rate (%)',
            column_search: 'Unique Search',
            column_search_rate: 'Search Rate (%)',
            column_shares: 'Paid Shares',
            column_sound_usage_clicks: 'Sound Usage Clicks',
            column_spend: 'Total cost',
            column_spend_credits: 'Unique Spend Credit',
            column_spend_credits_rate: 'Spend Credit Rate (%)',
            column_start_trial: 'Unique Start Trial',
            column_start_trial_rate: 'Start Trial Rate (%)',
            column_stitch_clicks: 'Stitch Clicks',
            column_subscribe: 'Unique Subscribe',
            column_subscribe_rate: 'Subscribe Rate (%)',
            column_total_achieve_level: 'Achieve Level (Total No.)',
            column_total_achieve_level_value: 'Total Achieve Level Value',
            column_total_active_pay_roas: 'Purchase ROAS',
            column_total_add_billing_value: 'Total Add Billing Value',
            column_total_add_payment_info: 'Total Add Payment Info',
            column_total_add_to_wishlist: 'Total Add to Wishlist',
            column_total_add_to_wishlist_value: 'Total Add to Wishlist Value',
            column_total_app_event_add_to_cart: 'Total Add to Cart',
            column_total_app_event_add_to_cart_value: 'Total Add to Cart Value',
            column_total_button_click_value: 'Total Button Click Value',
            column_total_checkout: 'Total Checkout',
            column_total_checkout_value: 'Total Checkout Value',
            column_total_complete_payment_rate: 'Total Complete Payment Value',
            column_total_complete_tutorial: 'Complete Tutorial (Total No.)',
            column_total_complete_tutorial_value:
                'Total Complete Tutorial Value',
            column_total_create_gamerole: 'Create Role (Total No.)',
            column_total_create_gamerole_value: 'Total Create Role Value',
            column_total_create_group: 'Create Group (Total No.)',
            column_total_create_group_value: 'Total Create Group Value',
            column_total_download_start_value: 'Total Download Value',
            column_total_form_value: 'Total Submit Form Value',
            column_total_in_app_ad_click: 'In-App Ad Click (Total No.)',
            column_total_in_app_ad_click_value: 'Total In-App Ad Click Value',
            column_total_in_app_ad_impr: 'In-App Ad Impr (Total No.)',
            column_total_in_app_ad_impr_value: 'Total In-App Ad Impr Value',
            column_total_initiate_checkout_value:
                'Total Initiate Checkout Value',
            column_total_join_group: 'Join Group (Total No.)',
            column_total_join_group_value: 'Total Join Group Value',
            column_total_landing_page_view: 'Total Landing Page View',
            column_total_launch_app: 'Launch App (Total No.)',
            column_total_loan_apply: 'Loan Apply (Total No.)',
            column_total_loan_credit: 'Loan Approval (Total No.)',
            column_total_loan_disbursement: 'Loan Disbursement (Total No.)',
            column_total_login: 'Login (Total No.)',
            column_total_next_day_open: 'Day 1 Retention (Total No.)',
            column_total_on_web_add_to_wishlist_value:
                'Total Add To Wishlist Value',
            column_total_on_web_order_value: 'Total Place an Order Value',
            column_total_on_web_subscribe_value: 'Total Subscribe Value',
            column_total_online_consult_value: 'Total Contact Value',
            column_total_onsite_add_billing_value:
                'Total Add Billing Value (Onsite)',
            column_total_onsite_add_to_wishlist_value:
                'Total Add To Wishlist Value (Onsite)',
            column_total_onsite_form_value:
                'Total Form Submission Value (Onsite)',
            column_total_onsite_initiate_checkout_count_value:
                'Total Initiate Checkout Value (Onsite)',
            column_total_onsite_on_web_cart_value:
                'Total Add to Cart Value (Onsite)',
            column_total_onsite_on_web_detail_value:
                'Total Product Details Page View Value (Onsite)',
            column_total_onsite_shopping_value: 'Gross revenue (Shop)',
            column_total_page_browse_view_value: 'Value per Page Browse',
            column_total_page_event_search_value: 'Total Search Value',
            column_total_pageview: 'Total Page View',
            column_total_product_details_page_browse_value:
                'Total Product Details Page Browse Value',
            column_total_purchase: 'Purchase (Total No.)',
            column_total_purchase_value: 'Total Purchase Value',
            column_total_ratings: 'Rate (Total No.)',
            column_total_ratings_value: 'Total Rate Value',
            column_total_registration: 'Total Registration',
            column_total_sales_lead: 'Generate Lead (Total No.)',
            column_total_sales_lead_value: 'Total Generate Lead Value',
            column_total_search: 'Total Search',
            column_total_spend_credits: 'Spend Credit (Total No.)',
            column_total_spend_credits_value: 'Total Spend Credit Value',
            column_total_start_trial: 'Start Trial (Total No.)',
            column_total_subscribe: 'Subscribe (Total No.)',
            column_total_subscribe_value: 'Total Subscribe Value',
            column_total_unlock_achievement: 'Unlock Achievement (Total No.)',
            column_total_unlock_achievement_value:
                'Total Unlock Achievement Value',
            column_total_user_registration_value:
                'Total Complete Registration Value',
            column_total_value_per_pageview: 'Total Page View Value',
            column_total_view_content: 'Total View Content',
            column_total_view_content_value: 'Total View Content Value',
            column_total_web_event_add_to_cart_value: 'Total Add to Cart Value',
            column_unique_custom_app_events: 'Unique Custom App Event',
            column_unlock_achievement: 'Unique Unlock Achievement',
            column_unlock_achievement_rate: 'Unlock Achievement Rate (%)',
            column_user_registration: 'Total Complete Registration',
            column_user_registration_rate: 'Complete Registration Rate (%)',
            column_value_per_add_billing: 'Value per Add Billing',
            column_value_per_button_click: 'Value per Button Click',
            column_value_per_checkout: 'Value per Checkout',
            column_value_per_complete_payment: 'Value per Complete Payment',
            column_value_per_custom_app_event: 'Value per Custom App Event',
            column_value_per_custom_page_event: 'Value per Custom Page Event',
            column_value_per_download_start: 'Value per Download',
            column_value_per_form: 'Value per Submit Form',
            column_value_per_initiate_checkout: 'Value per Initiate Checkout',
            column_value_per_offline_contact_event:
                'Value per Contact (Offline)',
            column_value_per_offline_form_event:
                'Value per Submit Form (Offline)',
            column_value_per_offline_shopping_event:
                'Value per Complete Payment (Offline)',
            column_value_per_offline_subscribe_event:
                'Value per Subscribe (Offline)',
            column_value_per_on_web_add_to_wishlist:
                'Value per Add To Wishlist',
            column_value_per_on_web_order: 'Value per Place an Order',
            column_value_per_on_web_subscribe: 'Value per Subscribe',
            column_value_per_online_consult: 'Value per Contact',
            column_value_per_onsite_add_billing:
                'Value per Add Billing (Onsite)',
            column_value_per_onsite_add_to_wishlist:
                'Value per Add To Wishlist (Onsite)',
            column_value_per_onsite_form: 'Value per Form Submission (Onsite)',
            column_value_per_onsite_initiate_checkout_count:
                'Value per Initiate Checkout (Onsite)',
            column_value_per_onsite_on_web_cart:
                'Value per Add to Cart (Onsite)',
            column_value_per_onsite_on_web_detail:
                'Value per Product Details Page View (Onsite)',
            column_value_per_onsite_shopping:
                'Value per Complete Payment (Onsite)',
            column_value_per_page_browse_view: 'Total Page Browse Value',
            column_value_per_page_event_search: 'Value per Search',
            column_value_per_product_details_page_browse:
                'Value per Product Details Page Browse',
            column_value_per_total_achieve_level: 'Value per Achieve Level',
            column_value_per_total_add_to_wishlist: 'Value per Add to Wishlist',
            column_value_per_total_app_event_add_to_cart:
                'Value per Add to Cart',
            column_value_per_total_complete_tutorial:
                'Value per Complete Tutorial',
            column_value_per_total_create_gamerole: 'Value per Create Role',
            column_value_per_total_create_group: 'Value per Create Group',
            column_value_per_total_in_app_ad_click: 'Value per In-App Ad Click',
            column_value_per_total_in_app_ad_impr: 'Value per In-App Ad Impr',
            column_value_per_total_join_group: 'Value per Join Group',
            column_value_per_total_purchase: 'Value per Purchase',
            column_value_per_total_ratings: 'Value per Rate',
            column_value_per_total_sales_lead: 'Value per Generate Lead',
            column_value_per_total_spend_credits: 'Value per Spend Credit',
            column_value_per_total_subscribe: 'Value per Subscribe',
            column_value_per_total_unlock_achievement:
                'Value per Unlock Achievement',
            column_value_per_total_view_content: 'Value per View Content',
            column_value_per_user_registration:
                'Value per Complete Registration',
            column_value_per_web_event_add_to_cart: 'Value per Add to Cart',
            column_video_play_actions: 'Video views',
            column_video_views_p100: 'Video Views at 100%',
            column_video_views_p25: 'Video Views at 25%',
            column_video_views_p50: 'Video Views at 50%',
            column_video_views_p75: 'Video Views at 75%',
            column_video_watched_2s: '2-Second Video Views',
            column_video_watched_6s: '6-Second Video Views',
            column_view_content: 'Unique View Content',
            column_view_content_rate: 'View Content Rate (%)',
            column_vta_app_install: 'VTA App Install',
            column_vta_conversion: 'VTA Conversion',
            column_vta_purchase: 'VTA Purchase',
            column_vta_registration: 'VTA Registration',
            column_web_event_add_to_cart: 'Add to Cart',
            column_web_event_add_to_cart_rate: 'Add to Cart Rate (%)',
            community_interaction: 'Community interaction',
            complete_payment: 'Complete payment',
            condition_and_action: 'Condition and action',
            config_all: 'Config For All',
            config_auto_ads: 'Config Auto Ads',
            config_live_ads: 'Config Live Ads',
            config_type: 'Config Type',
            connection_type: 'Connection type',
            consideration: 'Consideration',
            content_exclusions: 'Content exclusions',
            copy_adgroup_with_new_attr:
                'Duplicate the new ad group with this attribute',
            cost_per_1000_reached: 'Cost per 1000 people reached',
            cost_per_result: 'Cost per result',
            create_campaign_success: 'You have created a successful campaign',
            create_identity: 'Create Identity',
            create_new: 'Create new',
            create_new_adgroup_from_created_campaign: 'Create ad group',
            create_other_campaign: 'Create a campaign',
            desc_create_new_adgroup_from_created_campaign:
                'Click "Add ad group" to create more ad groups from the campaign you just created',
            desc_create_other_campaign:
                'Click "Create campaign" to create a new campaign',
            create_rule: 'Create new rule',
            create_split_test: 'Create split test',
            creator_interactions: 'Creator Interactions',
            creator_interactions_followed: 'Followed',
            creator_interactions_viewed_profile: 'Viewed profile',
            custom_column_attribution: 'Attribution',
            custom_column_basic_data: 'Basic data',
            custom_column_engagement: 'Engagement',
            custom_column_in_app_event: 'In app event',
            custom_column_interactive_add_on: 'Interactive add on',
            custom_column_live: 'LIVE',
            custom_column_offline_event: 'Offline event',
            custom_column_on_site_event: 'On site event',
            custom_column_page_event: 'Page event',
            custom_column_video_play: 'Video play',
            custom_targeting: 'Custom Targeting',
            daily_budget_spending_rate: 'Daily budget spending rate',
            dayparting: 'Dayparting',
            delivery_type: 'Delivery type',
            demographics: 'Demographics',
            desc_app_promotion:
                'Get more users to install and engage with your app.',
            desc_auto_refresh: 'Auto refresh after every interval',
            desc_automatic_targeting:
                'Optimize targeting based on advertising objective, ad content and past campaign data.',
            desc_campaign_budget_optimization:
                "Automatically optimize your budget allocation. You'll have some limitations on your bid strategy and optimization goal.",
            desc_catalog_sales:
                'Deliver personalized product ads to the most relevant users.',
            desc_community_interaction: 'Get more followers or profile visits.',
            desc_content_exclusions:
                'The inventory filter is only available for ads on the "For You" page in limited locations. Choose the tier most suitable for your brand.',
            desc_create_campaign_success:
                'To facilitate campaign creation, you can choose the following actions:',
            desc_create_new_campaign:
                'Create a new campaign and start growing on TikTok today!',
            desc_create_split_test:
                'Get more insights into your ads by split testing your strategy.',
            desc_creator_interaction:
                "Target users based on how they've interacted with creators on TikTok.",
            desc_creator_interactions:
                "Define how they've interacted with creators",
            desc_custom_target:
                'You can create templates and reuse them, reducing your time and optimizing your ads based on your saved performance templates',
            desc_custom_targeting:
                'Manually set criteria for what kind of user you want to show your ads to.',
            desc_following_hashtags:
                'Target users based on how they’ve interacted with hashtags on TikTok.',
            desc_full_inventory:
                'Your ads may appear next to some content featuring mature themes.*',
            desc_interest_keywords:
                'You can use a seed keyword to get a list of recommended interest keywords that you can use in your ad groups',
            desc_interests:
                'Target users based on their general interests or purchase intentions.',
            desc_lead_generation: 'Collect leads for your business or brand.',
            desc_limited_inventory:
                "Your ads will appear next to content that doesn't contain mature themes.",
            desc_live_shopping:
                'Promote products in a LIVE video. Users can purchase your products throughout the stream.',
            desc_max_create_xxx_ad:
                'You can create up to {xxx} ads under TopView ad group.',
            desc_max_create_xxx_adgroup: 'Max {xxx} ad groups',
            desc_pacing_mode_fast:
                'The system helps to spend your budget, get targeting results as quickly as possible and maximize the effectiveness of reaching your audience.',
            desc_pacing_mode_smooth:
                'Your budget and ads are distributed evenly based on the business traffic during the selected advertising period. If the traffic is heavy, your ads are sent more frequently, and vice versa.',
            desc_reach: 'Show your ad to the maximum number of people.',
            desc_recommended_interest_keyword:
                'These keywords are analyzed and selected by Tiktok and Atosa',
            desc_schedule_from_end: 'Run ad group within a date range',
            desc_schedule_from_end_shopping_live: 'Set duration for LIVE ads',
            desc_schedule_from_now:
                'Run ad group continuously after the scheduled start time',
            desc_set_campaign_budget:
                "All your ad groups in the campaign will stop once you've reached the daily or lifetime budget limit.",
            desc_PRODUCT_SALES:
                'Drive users to purchase products in your shop on TikTok',
            desc_shop_settings_identity:
                'Select a TikTok account as your identity. You can use posts or LIVE videos from this account as ad creatives.',
            desc_shopping_ads_type:
                'Ads will only run at the scheduled time and while the LIVE is ongoing.',
            desc_single_video:
                'Create an ad with one video or multiple images.',
            desc_standard_inventory:
                'Your ads will appear next to content that is appropriate for most brands and may contain some mature themes.',
            desc_traffic:
                'Send more people to a destination on your website or app.',
            desc_use_video_live:
                'The LIVE video on TikTok will be used as your ad creative.',
            desc_user_comment:
                'We recommend keeping user comments on to help your ads achieve more impressions and conversions. You can hide, pin, and reply to comments using our comment management tools.',
            desc_video_download:
                "Users based in Japan won't be able to download your video ads.\n Users can only download Spark Ad posts if this setting\n has been turned on in both Ads Manager and TikTok.",
            desc_video_interaction:
                "Target users based on how they've interacted with videos on TikTok.",
            desc_video_interactions:
                "Define how they've interacted with the videos",
            desc_video_sharing:
                "Turn on the toggle so users can share your video on TikTok.\n If the toggle is off, users won't be able to share your video via Instagram,\n direct message, SMS, Facebook, Twitter, email, or by copying the link.\n Other sharing options will still be available.",
            desc_video_shopping:
                'Add a product to videos that users can purchase from',
            desc_video_views:
                'Get more views and engagement for your video ads.',
            desc_view_data_audience:
                'Note: These values are estimated. The audience data will take 8 hours on average to process before the updated information is released.',
            desc_website_conversions: 'Drive valuable actions on your website.',
            destination_website: 'Destination website',
            device_model: 'Device Model',
            device_price: 'Device price',
            edit_auto_ads: 'Edit Auto Ads',
            edit_live_ads: 'Edit Live Ads',
            end_time: 'End Time',
            exceed_duration_xxx_days:
                'The selected duration cannot exceed {xxx} days',
            external_event: 'External event',
            external_website: 'External website',
            followers: 'Follows',
            following_hashtags: 'Following Hashtags',
            frequency: 'Frequency',
            frequency_cap: 'Frequency cap',
            frequency_show_ads_3_times_7_days:
                'Show ads no more than 3 times every 7 days',
            frequency_show_ads_custom_frequency_cap: 'Custom frequency cap',
            frequency_show_ads_more_than_once_day:
                'Show ads no more than once a day',
            from_library: 'From Library',
            full_inventory: 'Full inventory',
            get_notification_every_time:
                'You’ll get a notification every time the rule runs, even if conditions aren’t met.',
            impression_tracking_url: 'Impression tracking URL',
            in_app_event: 'In-app event',
            initiate_checkout: 'Initiate checkout',
            install: 'Installation',
            interest_keywords: 'Interest keywords',
            interests: 'Interests',
            interests_and_behaviours: 'Interests & Behaviours',
            kind_config_default: 'Default Config',
            kind_config_list: 'Auto Config',
            lead_generation: 'Lead Generation',
            lifetime_budget_spending_rate: 'Lifetime budget spending rate',
            limited_inventory: 'Limited inventory',
            list_custom_target: 'List Template',
            list_custom_target_adgroup: 'List template adgroup',
            live_ads: 'Live Ads',
            live_shopping: 'LIVE shopping',
            live_video: 'LIVE video',
            manage_comment: 'How to manage comments',
            manage_rule: 'Manage rules',
            max_cost: 'Max Cost',
            max_cpa: 'Max CPA',
            maximum_bid_cap: 'Maximum Bid cap',
            maximum_daily_budget_cap: 'Maximum Daily budget cap',
            maximum_lifetime_budget_cap: 'Maximum Lifetime Budget cap',
            message_adgroup_doesnt_have_any_ads:
                'This ad group does not have any ads yet, you need to create a new ad',
            message_adgroup_identity_doesnt_connect:
                'You no longer have access to the TikTok account used in this ad. To continue editing, select a new identity and creative material or re-apply for access',
            message_adgroup_store_doesnt_connect:
                'You no longer have access to the shop used in this ad. To continue editing, select a new shop or re-apply for access',
            message_clear_ad_list:
                "The setting for all ads you've created will be deleted. do you want to continue?",
            message_confirm_delete: 'You will delete this config?',
            message_create_config_auto_failed: 'Create Auto Config Failed',
            message_create_config_auto_success: 'Create Auto Config Success',
            message_delete_config_auto_failed: 'Delete Auto Config Failed',
            message_delete_config_auto_success: 'Delete Auto Config Success',
            message_edit_config_auto_failed: 'Edit Auto Config Failed',
            message_edit_config_auto_success: 'Edit Auto Config Success',
            message_live_ads_cant_duplicated:
                "Live shopping ads can't be duplicated",
            message_update_status_xxx_failed: 'Update Status {xxx} Failed',
            message_update_status_xxx_success: 'Update Status {xxx} Success',
            message_update_mess_status_success:
                'Batch status change successful',
            message_delete_xxx_failed: 'Delete {xxx} Failed',
            message_delete_xxx_success: 'Delete {xxx} Success',
            minimum_bid: 'Minimum Bid',
            minimum_daily_budget: 'Minimum Daily budget',
            minimum_lifetime_budget: 'Minimum Lifetime Budget',
            noti_desc_full_inventory_1:
                "*All inventory within Full, Standard, and Limited Inventory excludes content that TikTok is aware violates TikTok's Community Guidelines, Intellectual Property Policy, and Terms of Service.",
            noti_desc_full_inventory_2:
                "We'll use reasonable efforts to exclude content consistent with your tier selection but we cannot guarantee the accuracy of our content exclusions.",
            notification_schedule: 'Notification schedule',
            notify_me: 'Notify me',
            notify_me_every_time: 'Notify me every time',
            objective_type: 'Objective Type',
            of_each_object: 'Of each object',
            on_going: 'On going',
            once_in_1_w: 'Once a week',
            once_in_24_h: 'Once every 24 hours',
            once_in_48_h: 'Once every 48 hours',
            only_once: 'Only once',
            optimization_event: 'Optimization event',
            optimization_goal: 'Optimization goal',
            optimization_goal_engaged_view: '6-second views (Focused view)',
            optimization_goal_lead_generation: 'Leads',
            optimization_goal_minimum_roas: 'Minimum ROAS',
            optimization_goal_highest_roas: 'Highest gross revenue',
            optimization_goal_desc_highest_roas:
                'Spend your budget fully while maximizing purchase value.',
            os_versions: 'OS versions',
            overall_trends: 'Overall trends',
            pixel_event_add_payment_info: 'Add Payment Info',
            pixel_event_add_to_cart: 'Add to Cart',
            pixel_event_add_to_wishlist: 'Add to Wishlist',
            pixel_event_click_button: 'Click Button',
            pixel_event_complete_payment: 'Complete Payment',
            pixel_event_complete_registration: 'Complete Registration',
            pixel_event_consult: 'Consult',
            pixel_event_download: 'Download',
            pixel_event_initiate_checkout: 'Initiate Checkout',
            pixel_event_on_search: 'Search',
            pixel_event_place_an_order: 'Place an Order',
            pixel_event_submit_form: 'Form',
            pixel_event_subscribe: 'Subscribe',
            pixel_event_view_content: 'View Content',
            placements: 'Placements',
            please_budget_than_min:
                'Please enter a budget greater than or equal to {budget}',
            please_input_ad_format_landing_page_url:
                'Please input ad format landing page url',
            please_input_ad_landing_page_url:
                'Please input ad landing page url',
            please_input_ad_name: 'Please input ad name',
            please_input_ad_text: 'Please input ad text',
            please_input_adgroup_name: 'Please input ad group name',
            please_input_bid_price: 'Please input the bid price',
            please_input_bid_price_lowser_budget:
                'Bid must be less than budget',
            please_input_campaign_name: 'Please input campaign name',
            please_input_conversion_bid:
                'Please input the conversion bid price',
            please_input_end_time_more_than_start_time:
                'The end time must be greater than the start time',
            please_input_identity_display_name:
                'Please input your identity display name',
            please_input_start_time_more_than_current:
                'Start time must be greater than current time',
            please_input_video: 'Please input video',
            please_select_app: 'Please select App',
            please_select_event: 'Please select event',
            please_select_identity: 'Please select identity',
            please_select_identity_id: 'Please select an identity',
            please_select_input_campaign_name: 'Please input a campaign name',
            please_select_input_rule_name: 'Please inmput a rule name',
            please_select_location: 'Please select location',
            please_select_pixel: 'Please select a pixel',
            please_select_products: 'Please select products',
            please_vsa_select_max_n_products:
                'VSA ads only select up to {n} products',
            please_psa_select_max_n_products:
                'PSA ads only select up to {n} products',
            please_select_store_id: 'Please select store',
            post_delivered: 'Your post will delivered from',
            product_click_in_live: 'Product click in LIVE',
            product_post_delivered:
                'Your product and post will be delivered from',
            product_sales: 'Product sales',
            profile_views: 'Profile Visits',
            promotion_type: 'Promotion type',
            real_time_conversion: 'Real-time conversion',
            real_time_conversion_rate: 'Real-time CVR',
            real_time_cost_per_conversion: 'Real-time CPA',
            real_time_cost_per_result: 'Real-time cost per result',
            real_time_result: 'Real-time result',
            real_time_result_rate: 'Real-time result rate',
            receive_notifications_by_email:
                'Receive notifications by email when your rule conditions are met.',
            recommended_interest_behavior_categories:
                'Recommended interest and/or behavior categories',
            recommended_interest_keyword:
                'Keywords are searched a lot and are rising',
            result_rate: 'Result rate',
            rule_name: 'Rule Name',
            rule_settings: 'Rule settings',
            run_at_specific_times: 'Run at Specific Times',
            run_daily: 'Run daily',
            run_every_15_minutes: 'Run every 15 minutes',
            run_every_30_minutes: 'Run every 30 minutes',
            run_rule_every_30_minute:
                'Run the rule every 30 minutes during specific time periods.',
            run_schedule: 'Run schedule',
            select_at_least_one_category: 'Select at least one category',
            select_at_least_one_type_of_action:
                'Select at least one type of action',
            select_rule_for:
                'Select the campaign, ad group, or ad you want to apply the rule to.',
            select_specific_time: 'Select specific time',
            select_target_mode: 'Select targeting mode',
            select_time_period: 'Select time period',
            send_notification_together:
                'Send notifications together around a specified time',
            set_campaign_budget: 'Set campaign budget',
            set_custom_identity: 'Set custom identity',
            set_tiktok_account_to_identity:
                'Set what TikTok account you’d like to use',
            PRODUCT_SALES: 'Product sales',
            shop_settings: 'Shop settings',
            single_video: 'Single Video',
            skip_return_campaign_list: 'Skip to return to campaign list',
            specific_price: 'Specific range',
            standard_inventory: 'Standard inventory',
            standard_video: 'Standard video',
            start_time: 'Start Time',
            target_campaigns: 'Target campaigns',
            targeting: 'Targeting',
            targeting_summary: 'Targeting summary',
            third_party_tracking_settings: 'Third party tracking settings',
            tiktok_account_hasnt_ads_account:
                'Tiktok account does not have an ad account !!!',
            tiktok_instant_page: 'Tiktok instant page',
            tiktok_post: 'Tiktok post',
            time_of_action: 'Time of action',
            time_range: 'Time range',
            traffic_landing_page_view: 'Landing Page View',
            try_xxx_interest_behavior_categories:
                'To reach more relevant users, try these {xxx} interest and behavior categories',
            type_live_ads: {
                click: 'click',
                convert: 'convert',
                mt_live_room: 'mt_live_room',
                product_click_in_live: 'product_click_in_live'
            },
            use_account_owned_by_you: 'Use authorized account',
            use_existing: 'Use existing',
            use_spark_ads: 'Use TikTok account to deliver Spark Ads',
            user_comment: 'User comment',
            video_download: 'Video download',
            video_interactions: 'Video Interactions',
            video_interactions_commented: 'Commented',
            video_interactions_liked: 'Liked',
            video_interactions_period:
                'Select a time period to include actions from',
            video_interactions_shared: 'Shared',
            video_interactions_watch_till_end: 'Watched till end',
            video_library: 'Video Library',
            video_sharing: 'Video sharing',
            video_shopping: 'Video shopping',
            video_view: 'Play video',
            video_views: 'Video views',
            viewer_retention: 'Viewer Retention',
            website_conversions: 'Website conversions',
            xxx_ad_has_selected: 'Selected {xxx} ads',
            xxx_adgroup_has_selected: 'Selected {xxx} adgroups',
            xxx_campaign_has_selected: 'Selected {xxx} campaigns'
        },
        connect_tiktok_account: {
            assign_to_bc: 'Assign to BC',
            choose_bc: 'Choose Business',
            connect_for_business: 'Connect Tiktok For Business',
            connect_success:
                'You have successfully connected your tiktok account',
            connect_your_tiktok_ads: 'Connect your Tiktok Ads Account',
            message_connect_ads_account_error:
                'An error occurred while creating Advertiser account',
            message_connect_ads_account_success:
                'Create Advertiser account successfully',
            name_company: "Company's Name",
            title: 'Set up Tiktok For Business',
            title_ads_account: 'Tiktok Advertiser Account',
            title_business_account: 'Tiktok For Business Account',
            title_business_center: 'Tiktok For Business Center'
        },
        login: {
            message_for_reset_password:
                'You have successfully changed your password',
            reset_password:
                'Hello <span class="font-semibold">{display_name}</span>! Please set a new password for account <span class="font-semibold">{username}</span>',
            policy: 'By signing up, you agree to Atame <a target="_blank" href="https://atosa.io/policy" class="underline text-brand-atosa">Terms of Service and Privacy policy</a>',
            dont_have_an_account: "You don't have an account",
            forgot_password: 'Forgot Password',
            have_an_account: 'You have an account',
            login_now: 'Login now',
            placeholder: {
                email: 'Email',
                name: 'Name',
                password: 'Password',
                phone: 'Phone Number',
                username: 'Username'
            },
            register: 'Register',
            register_now: 'Register now',
            thanks_for_registering:
                'Thank you! We have sent login info to your email',
            message_for_forget_password:
                'Atame will send information to this email. Please read the letter and follow the instructions',
            desc_forget_password:
                'Atame will send password reset information to your email',
            title: 'Login',
            here: 'here',
            join_course:
                'Join the effective Shopee and Tiktok Advertising course',
            learn_more: 'Learn more about the safest Shopee Ads support tool',
            ads_management_toolkit: 'TikTok Ads Management Toolkit',
            seo_keyword_1: 'Automatic tiktok advertising software',
            seo_keyword_2: 'Automatic tiktok advertising support software',
            seo_keyword_3: 'Tiktok advertising support software',
            seo_keyword_4: 'Automatic tiktok advertising',
            seo_keyword_5: 'Tiktok advertising',
            seo_keyword_6: 'Manage tiktok advertising accounts',
            seo_keyword_7: 'Manage tiktok account',
            seo_keyword_8: 'Manage tiktok ads',
            seo_keyword_9: 'Software to run tiktok ads'
        },
        member: {
            access_assigned_ad_accounts: 'Access assigned advertiser accounts',
            ads_manager: 'Ads Manager',
            change_business_info: 'Change business info',
            desc_assign_member_dialog:
                "Enter the email addresses of any members you'd like to invite to this Business Center account and set their access permissions.",
            email_placeholder:
                'You can enter multiple emails by pressing Enter after each one.',
            invite: 'Invite',
            invite_member_successfully: 'Invite member successfully',
            invite_new_member: 'Invite new member',
            manage_ad_accounts: 'Manage ad accounts',
            manage_members: 'Manage members',
            pending: 'Pending',
            view_business_info: 'View business info'
        },
        not_found: {
            desc: "We 're sorry , but the page you are looking for can't be found."
        },
        onboarding: {
            active_1_1:
                'Only TikTok For Business users can create ad accounts, make TikTok Business Center accounts, be assigned a role, and receive access to assets. You can become a TikTok For Business users by signing up on the following links.',
            active_1_2: 'Connect your Tiktok For Business Account.',
            active_1_3:
                'Select your Tiktok For Business account to create an Ad Account',
            active_2_1:
                'Business Center is a one-stop business hub for organizations of all sizes to activate marketing activities and reach desired audiences in a collaborative way on TikTok. Business Center enables organizations to centralize their assets and teams in one place, enabling permissions allocation and asset usage in an efficient and safe way.',
            active_2_2: 'Create your Business Center.',
            active_2_3:
                'Select your Business Center and create new Advertiser Account.',
            active_2_3_2:
                'Select your Business Center and assign the new Advertiser Account.',
            active_2_4: 'Add balance to new Advertiser Account.',
            active_3_1:
                'Creating an Advertiser Account on Atosa will help you to add balance quickly and efficiently.',
            active_3_2:
                'Create Account Advertiser and go to account add balance.',
            active_3_2_2: 'Create Account Advertiser and create new BC.',
            add_balance_now: 'Add balance now!',
            complete_onboarding_message:
                'Congratulations on completing the Advertiser Account creation process. Here, please add balance to your ad account to be able to create campaigns to promote your products!',
            guide: 'Guide to create Tiktok Advertiser Account'
        },
        package: {
            can_buy_more:
                'When you purchase a premium plan, you can purchase additional value of this feature!',
            buy_more: 'Buy more',
            extra_field: {
                max_tiktok_account:
                    'Maximum management of business tiktok accounts',
                max_ad_account:
                    'Each business tiktok account creates a maximum of new advertising accounts',
                max_bc: 'Each business tiktok account creates a new maximum business center',
                auto_ads: 'Create maximum auto rules',
                recharge_online: 'Recharge money into account online',
                recharge_to_ads_account:
                    'Deposit money directly into QC Account easily and quickly, suitable for Agency',
                clone_campaign: 'Copy Campaign and Multiple Ad Groups',
                analysis_data:
                    'Detailed analysis of parameters of each campaign',
                permission_second_account: 'Sub-account permission',
                manage_target: 'Manage target object files',
                create_campaigns_in_1_time:
                    'Create multiple ads and ad groups at the same time, and each group has a separate target and audience file',
                edit_copy_campaign:
                    'When copying multiple ad groups, allow detailed editing of each ad group',
                compare_data: 'Compare data by date',
                analysis_hourly_data: 'See data by hour of the day',
                keyword_page: 'Video advertising keyword tool',
                manager_campaign_template:
                    'Manage ad group templates, campaign group templates',
                delete_campaign: 'Delete campaign, ad group',
                filter_campaign_by_conditions:
                    'Filter campaigns, ad groups by detailed conditions or multiple conditions',
                auto_refresh_campaign: 'Automatically update campaign data',
                copy_adgroup_to_campaign:
                    'Copy multiple ad groups (maximum 5 groups) to many different campaigns',
                internal_account_transfer:
                    'Flexibly transfer money between advertising accounts',
                create_adgroup_by_campaign:
                    'Create quick ad groups from campaigns',
                report_by_ads_account:
                    'Effectiveness report -> consolidate multiple advertising accounts',
                crud_target_when_campaign_run:
                    'Add or remove target groups while running ads',
                permission_target: 'Audio file permissions by ad account'
            },
            ads_management: 'Add , edit , delete , duplicate campaigns',
            buy_more_auto_ads: 'Buy more to create more rules automatically',
            buy_more_tiktok_accounts: 'Buy more tiktok account management',
            buy_more_ads_accounts:
                'Buy more to create new ad accounts of each tiktok account',
            buy_more_bc:
                'Buy more create new business center of each tiktok account',

            extra_purchase_package: 'Extra purchase package',
            detailed_analysis:
                'Detailed analysis of the parameters of each campaign',
            manage_maximum_xxx_tiktok_accounts:
                'Manage up to <span class="font-semibold">{xxx}</span> tiktok accounts',
            manage_maximum_xxx_ads_accounts:
                'Each tiktok account creates a maximum of <span class="font-semibold">{xxx}</span> ad accounts',
            manage_maximum_xxx_bc:
                'Up to each new tiktok account <span class="font-semibold">{xxx}</span> business center',
            ad_accounts: 'AD Accounts',
            add_balance_easily_etc: 'Add AD Account balance easily and quickly',
            auto_optimize_xxx_ad:
                'Create up to <span class="font-semibold">{xxx}</span> automated rules',
            auto_optimize_ad_etc:
                'Auto Optimize Your Ads and many more utilities',
            change_plan: 'See more packages',
            choose_plan: 'Choose Plan',
            copy_campaign_etc: 'Copy Campaign And Multiple Ad Groups',
            create_maximum: 'Create maximum',
            current_plan: 'Current Plan',
            enjoy_all_the_feature: 'Enjoy All The Features',
            register_from: 'Register from',
            license_to: 'License to',
            manage_maximum: 'Manage maximum',
            our_best_pricing: 'Our Best Pricing'
        },
        payment: {
            invoice: 'Export invoice',
            tax_code: 'Tax code',
            company_name: 'Company name',
            company_phone: 'Company phone number',
            company_address: 'Company address',
            company_email: 'Invoice receipt email',
            ad_account_list: 'AD Account List',
            ad_account_transaction_history: 'AD Account Transaction History',
            add_balance: 'Add Balance',
            add_balance_title: 'Add Balance to Your AD Account',
            add_balance_to_ads_account: 'Add balance to Advertiser Account',
            add_balance_to_atosa_account: 'Add balance to the Atosa Account',
            add_fund: 'Add Fund',
            add_fund_successfully: 'Add fund successfully',
            add_money_store: 'Add Balance',
            add_price_order_cancel_order: 'Refund',
            atosa_account_transaction_history:
                'Atosa Account Transaction History',
            atosa_balance: 'Atosa Balance',
            cash_amount: 'Cash amount',
            confirm_add_fund_message:
                'Do you want to add fund to this ad account?',
            enter_cash_amount: 'Enter cash amount',
            online_recharge: 'Online Recharge',
            payment_note:
                'You can only add balance to the Advertiser Accounts which created by the system',
            recharge: 'Recharge',
            sub_buy_point: 'Buy Point',
            sub_money_store: 'Subtract Balance',
            sub_price_order: 'Buy Package',
            success_purchased: 'Successfully purchased',
            transaction_code: 'transaction code',
            your_balance_not_enough: 'Your account balance is not enough'
        },
        shop: {
            please_verify_again: 'Please redo the verification process',
            message_verify_success: 'Please create a new browser',
            verify_tiktok_shop_success:
                'Tiktok shop account authentication process successful!',
            verify_tiktok_shop_fail:
                'Tiktok shop account verification process failed!',
            verify_tiktok_business_success:
                'Successful business Tiktok account verification process!',
            verify_tiktok_business_fail:
                'Tiktok business account authentication process failed!',
            add_shop: 'Add shop',
            connect_shop: 'Connect Tiktok Shop',
            connect_tiktok_account: 'Connect Tiktok Ads Account',
            connect_tiktok_account_success:
                'Connect Tiktok Ads account success',
            connect_tiktok_shop_success: 'Connect Tiktok Shop success',
            dialog_note:
                'Enter the shop code of a TikTok Shop to add it to Business Center. An access request will be sent by email to the owner of the shop and any main administrators or advertising managers.',
            dialog_title: 'Request access to TikTok Shop',
            dont_have_tts: "Don't have Tiktok Shop yet?",
            enter_shop_code: 'Enter shop code',
            error: 'Error',
            has_connected: 'Connected',
            hasnt_connected: 'Not connected yet',
            how_find_it: 'How to find it?',
            how_find_it_message:
                'Find the code in TikTok Shop Seller Center > "My account" > "Seller profile" > "Seller information".',
            how_find_it_title: 'How to find the shop code',
            // info: 'To create a buy-in-store campaign, you need to do the following steps: Submit a request to manage Tiktok Shop -> Confirm from the URL -> Connect Tiktok Shop -> Connect a Tiktok Ads account with the same email as Tiktok Shop',
            info: 'To create a buy-in-store campaign, you need to do the following steps: Submit a request to manage Tiktok Shop -> Confirm from the URL -> Connect Tiktok Shop',
            manage_tiktok_shop: 'Submit request to manage Tiktok Shop',
            pending: 'Pending',
            process: 'Processing',
            register_new_tts: 'Register new Tiktok Shop',
            register_now: 'Register now',
            request_sent: 'Request sent',
            shop_code: 'Shop Code',
            status_connect: 'Status Connect',
            submit_url: 'Submit url',
            success: 'Approved'
        },
        tiktok_account: {
            desc_admin:
                'Manage ad account finances, settings, and permissions.Create and edit ad campaigns and view performance data.',
            desc_analyst: 'View ads and performance data.',
            desc_assign_member_dialog:
                'Select members from your Business Center account and set the access permission you’d like to give them.',
            desc_operator:
                'Create and edit ad campaigns and view performance data.',
            edit_custom_info: 'Edit tiktok account information',
            message_confirm_delete: 'You will delete this account?',
            message_delete_tiktok_account_fail: 'Delete Tiktok Account Failed',
            message_delete_tiktok_account_success:
                'Delete Tiktok Account Success',
            title: 'Tiktok Accounts'
        },
        tiktok_identity: {
            info: 'To get videos and articles in your Tiktok account for advertising purposes, please connect here',
            add_tiktok_account: 'Add Tiktok Account',
            confirm_linked_message: 'Have you verified the Tiktok Account?',
            copy_qr_code: 'Copy QR Code',
            dialog_content_1: 'Authorize Business Center "ATOSA GLOBAL"',
            dialog_content_2: 'Ad delivery with existing posts',
            dialog_content_3:
                'Access profile info | Access public videos | Access ad-enabled videos',
            dialog_content_4: 'Ad delivery with LIVE videos',
            dialog_content_5: 'Access profile info | Access LIVE videos',
            dialog_note:
                "Ask any TikTok account owner to scan this QR code to approve your request. After it's approved, the account will appear in your assets and you'll be able to give access to your members.",
            dialog_title: 'Request access to TikTok Account',
            enter_tiktok_account_id:
                'Enter Tiktok Account Id, eg: @atosa.official',
            get_qr_code: 'Get QR Code',
            how_find_it: 'How to find it?',
            how_find_it_message:
                'Find the Tiktok Account Id > Open Tiktok App > Profile > Copy text starts with "@". Eg: @atosa.official.',
            how_find_it_title: 'How to find the tiktok account id',
            scan_code: 'How to scan the code',
            scan_code_1: '1. Open the TikTok app and tap "Friends"',
            scan_code_2: '2. Tap  then tap  to scan the code',
            scan_code_3: '3. Authorize the access request',
            scan_code_4:
                '4. Press the "Confirm linked" on Atosa App to verify the Tiktok Account.',
            tiktok_id: 'Tiktok ID'
        },
        autobot: {
            add_autobot: 'Add new Auto Bot',
            add_action: 'Add Action',
            dialog_title: 'Add new Auto Bot',
            enter_autobot_name: 'Auto Bot Name',
            action: 'Action',
            target: 'Target',
            message_update_status_success: 'Update status success',
            message_update_status_error: 'Update status error',
            history: 'History'
        },
        affiliate: {
            code: 'Your marketing code',
            desc: "To join Atosa's affiliate marketing system, you just need to follow these steps",
            desc_1: '1 . Copy your marketing code',
            desc_2: '2 . Send marketing code to multiple users to receive commission',
            desc_3: "3 . Users pay for Atosa's services",
            desc_3_1:
                'In the service payment page, please instruct the user to enter your marketing code in <b>"With your referral"</b>',
            desc_4: '4 . The system will calculate and add to your balance when there is a transaction with your marketing code.'
        }
    },
    table: {
        header: {
            action: 'Action',
            ad_group_id: 'Ad Group ID',
            ad_group_name: 'Ad Group Name',
            mobile: 'Mobile',
            on_off: 'On/Off',
            total_cost: 'Total Cost',
            username: 'Username'
        }
    },
    warning: {
        message_close_wont_be_save: "Your progress won't be saved."
    }
}

export default lang
